import { defineStore } from 'pinia'
import { ref } from 'vue'
import axios from 'axios'

export const useWorkApprovalStore = defineStore('workApproval', () => {
  const pendingCount = ref(0)
  const pendingFieldEmployeeDayCount = ref(0)
  const pendingMarketingKytCount = ref(0)
  const pendingLeaveCount = ref(0)
  const pendingCdwCount = ref(0)
  const baseUrl = process.env.VUE_APP_BASE_URL

  async function fetchPendingCount() {
    try {
      console.log('Fetching pending counts...')
      const response = await axios.get(`${baseUrl}api/admin/counts/all`)
      console.log('API Response:', response.data)
      
      if (response.data.success) {
        pendingCount.value = response.data.data.pendingWork.pendingWorkCount
        pendingFieldEmployeeDayCount.value = response.data.data.pendingFieldEmployeeDay.pendingFieldEmployeeDayCount
        pendingMarketingKytCount.value = response.data.data.pendingMarketingChangeAndTransfer.pendingMarketingChangeAndTransferCount
        pendingLeaveCount.value = response.data.data.pendingLeave.pendingLeaveCount
        pendingCdwCount.value = response.data.data.pendingCdw.pendingCdwCount
        console.log('Updated pending counts:', {
          work: pendingCount.value,
          fieldEmployeeDay: pendingFieldEmployeeDayCount.value,
          marketingKyt: pendingMarketingKytCount.value,
          leave: pendingLeaveCount.value,
          cdw: pendingCdwCount.value
        })
      }
    } catch (error) {
      console.error('Error fetching pending counts:', error)
      pendingCount.value = 0
      pendingFieldEmployeeDayCount.value = 0
      pendingMarketingKytCount.value = 0
      pendingLeaveCount.value = 0
      pendingCdwCount.value = 0
    }
  }

  function setPendingCount(count: number) {
    console.log('Setting pending count:', count)
    pendingCount.value = count || 0
  }

  function setPendingFieldEmployeeDayCount(count: number) {
    console.log('Setting pending field employee day count:', count)
    pendingFieldEmployeeDayCount.value = count || 0
  }

  function setPendingMarketingKytCount(count: number) {
    console.log('Setting pending marketing KYT count:', count)
    pendingMarketingKytCount.value = count || 0
  }

  function setPendingLeaveCount(count: number) {
    console.log('Setting pending leave count:', count)
    pendingLeaveCount.value = count || 0
  }

  return {
    pendingCount,
    pendingFieldEmployeeDayCount,
    pendingMarketingKytCount,
    pendingLeaveCount,
    pendingCdwCount,
    fetchPendingCount,
    setPendingCount,
    setPendingFieldEmployeeDayCount,
    setPendingMarketingKytCount,
    setPendingLeaveCount
  }
}) 