import { jwtDecode, JwtPayload } from "jwt-decode";

interface CustomJwtPayload extends JwtPayload {
    permissions?: string[];
}

const token = localStorage.getItem('token');
const decodedToken = token ? jwtDecode<CustomJwtPayload>(token) : null;
const permissions = decodedToken?.permissions || [];

const filterMenuItemByPermission = (item: MenuItem): boolean => {
    if (!item.permission) return true;
    return permissions.includes(item.permission);
};

const filterMenuItems = (items: MenuItem[]): MenuItem[] => {
    return items.filter(item => {
        if (!filterMenuItemByPermission(item)) return false;

        if (item.children) {
            const filteredChildren = filterMenuItems(item.children);
            if (filteredChildren.length === 0 && item.type === 'sub') return false;
            item.children = filteredChildren;
        }

        return true;
    });
};

interface MenuItem {
    path?: string;
    title: string;
    icon?: string;
    type: "link" | "sub";
    active?: boolean;
    children?: MenuItem[];
    permission?: string;
    badge?: boolean;
}

interface MenuSection {
    title: string;
    active: boolean;
    children: MenuItem[];
}

interface MenuData {
    data: MenuSection[];
}

// Create the menu data and filter it
const rawMenu: MenuData = {
    data: [
        {
            title: "General",
            active: false,
            children: [
                {
                    path: "/",
                    title: "Dashborad",
                    icon: "home",
                    active: false,
                    type: "link",
                    permission: "view_dashboard"
                },
                {
                    title: "Website",
                    icon: "film",
                    type: "sub",
                    active: false,
                    permission: "view_website",
                    children: [
                        {
                            path: "/pages/home",
                            title: "Home",
                            icon: "film",
                            type: "link",
                            permission: "view_home"
                        },
                        {
                            path: "/pages/services-page",
                            title: "Services",
                            icon: "airplay",
                            type: "link",
                            permission: "view_services"
                        },
                        {
                            path: "/pages/whyus",
                            title: "Why Us",
                            icon: "airplay",
                            type: "link",
                            permission: "view_whyus"
                        },
                        {
                            path: "/pages/gallery",
                            title: "Gallery",
                            icon: "box",
                            type: "link",
                            permission: "view_gallery"
                        },
                        {
                            path: "/pages/contact-us",
                            title: "Contact Us",
                            icon: "box",
                            type: "link",
                            permission: "view_contact"
                        },
                        {
                            path: "/pages/testimonials",
                            title: "Testimonials",
                            icon: "box",
                            type: "link",
                            permission: "view_testimonials"
                        },
                        {
                            path: "/pages/apps",
                            title: "Apps Page",
                            icon: "monitor",
                            type: "link",
                            permission: "view_apps"
                        },
                        {
                            path: "/pages/join-us",
                            title: "Join Us",
                            icon: "shopping-bag",
                            type: "link",
                            permission: "view_joinus"
                        },
                        {
                            path: "/pages/clients",
                            title: "Clients",
                            icon: "shopping-bag",
                            type: "link",
                            permission: "view_clients"
                        },
                        {
                            path: "/app/contacts_data",
                            title: "Contacts",
                            icon: "mail",
                            type: "link",
                            active: false,
                            permission: "view_contacts"
                        },
                        {
                            path: "/app/file_manager",
                            title: "File manager",
                            icon: "git-pull-request",
                            type: "link",
                            active: false,
                            permission: "view_files"
                        }
                    ]
                },
                {
                    path: "/pages/join-us-submissions",
                    title: "Join Us Forms",
                    icon: "box",
                    type: "link",
                    permission: "view_joinforms"
                },
                {
                    path: "/users/list",
                    title: "Users",
                    icon: "users",
                    type: "link",
                    active: false,
                    permission: "view_users"
                },
                {
                    path: "/image/processing",
                    title: "Image Processing",
                    icon: "image",
                    type: "link",
                    active: false,
                    permission: "view_image_processing"
                },
                {
                    path: "/ratechart/list",
                    title: "Rate Charts",
                    icon: "users",
                    type: "link",
                    active: false,
                    permission: "view_rate_charts"
                },
                {
                    path: "/activity-log/log",
                    title: "Activity Log",
                    icon: "users",
                    type: "link",
                    active: false,
                    permission: "view_activity_log"
                },
                {
                    title: "Employees",
                    icon: "users",
                    type: "sub",
                    active: false,
                    permission: "view_employees",
                    children: [
                        {
                            path: "/employee-list/all-employee-list",
                            title: "All Employee List",
                            type: "link",
                            permission: "view_all_employees"
                        },
                        {
                            path: "/employees/field",
                            title: "Field Employees",
                            icon: "users",
                            type: "link",
                            permission: "view_field_employees"
                        },
                        {
                            path: "/employees/operation",
                            title: "Operation Employees",
                            icon: "users",
                            type: "link",
                            permission: "view_operation_employees"
                        },
                        {
                            path: "/employee-list/black-listed-employee",
                            title: "Black Listed Employee",
                            type: "link",
                            permission: "view_blacklisted_employees"
                        }
                    ]
                },
                {
                    path: "/import-employee/add",
                    title: "Import Employee",
                    icon: "clipboard",
                    type: "link",
                    active: false,
                    permission: "view_employees"
                },
                {
                    path: "/locked-employees/list",
                    title: "Locked Employees",
                    icon: "clipboard",
                    type: "link",
                    active: false,
                    permission: "view_employees"
                },

                {
                    path: "/leaves/list",
                    title: "Leaves",
                    icon: "calendar",
                    type: "link",
                    permission: "view_leaves",
                    badge: true
                },

                {
                    title: "KYT",
                    icon: "box",
                    type: "sub",
                    active: false,
                    permission: "view_kyt",
                    children: [
                        {
                            path: "/kyts/marketing",
                            title: "Marketing KYT",
                            type: "link",
                            permission: "view_marketing_kyt"
                        },
                        {
                            path: "/kyts/operation",
                            title: "Operation KYT",
                            type: "link",
                            permission: "view_operation_kyt"
                        },
                        {
                            title: "KYT Transfer",
                            path: "/kyts/transfer/request",
                            type: "link",
                            active: false,
                            permission: "view_kyt_transfer"
                        },
                        {
                            title: "KYT Change",
                            path: "/kyts/change/request",
                            type: "link",
                            active: false,
                            permission: "view_kyt_change"
                        }
                    ]
                },
                {
                    path: "/projects/list",
                    title: "Projects",
                    icon: "shopping-bag",
                    type: "link",
                    active: false,
                    permission: "view_projects"
                },
                {
                    path: "/cdw/list",
                    title: "CDW",
                    icon: "clipboard",
                    type: "link",
                    active: false,
                    permission: "view_cdw",
                    badge: true
                },
                {
                    path: "/field-employee-day/list",
                    title: "Field Employee Day",
                    icon: "clipboard",
                    type: "link",
                    badge: true
                },
                {
                    path: "/quotation/list",
                    title: "Quotation",
                    type: "link",
                    active: false,
                    permission: "view_quotations"
                },
                {
                    path: "/app-quoats/list",
                    title: "App Quoats",
                    type: "link",
                    active: false,
                    permission: "view_app_quotes"
                },
                {
                    path: "/assign-marketing-work/list",
                    title: "Assign Work",
                    type: "link",
                    active: false,
                    permission: "view_quotations"
                },
                {
                    path: "/assign-work-approval/list",
                    title: "Assign Work Approval",
                    type: "link",
                    active: false,
                    permission: "view_quotations",
                    badge: true
                },
                {
                    title: "Reports",
                    icon: "box",
                    type: "sub",
                    active: false,
                    permission: "view_reports",
                    children: [
                        {
                            title: "Training",
                            type: "sub",
                            active: false,
                            permission: "view_training_reports",
                            children: [
                                {
                                    path: "/reports/training/security-training",
                                    title: "Security Training",
                                    type: "link",
                                    permission: "view_security_training"
                                },
                                {
                                    path: "/reports/training/technical-training",
                                    title: "Technical Training",
                                    type: "link",
                                    permission: "view_technical_training"
                                }
                            ]
                        },
                        {
                            title: "FMMGC",
                            type: "sub",
                            active: false,
                            permission: "view_fmmgc",
                            children: [
                                {
                                    path: "/reports/fmmgc/format",
                                    title: "Format",
                                    type: "link",
                                    permission: "view_format"
                                },
                                {
                                    path: "/reports/fmmgc/grooming",
                                    title: "Grooming",
                                    type: "link",
                                    permission: "view_grooming"
                                },
                                {
                                    path: "/reports/fmmgc/machine",
                                    title: "Machine",
                                    type: "link",
                                    permission: "view_machine"
                                },
                                {
                                    path: "/reports/fmmgc/manpower",
                                    title: "Manpower",
                                    type: "link",
                                    permission: "view_manpower"
                                },
                                {
                                    path: "/reports/fmmgc/sop-knowledge",
                                    title: "SOP Knowledge",
                                    type: "link",
                                    permission: "view_sop_knowledge"
                                },
                                {
                                    path: "/reports/fmmgc/customised-sop",
                                    title: "Customised SOP",
                                    type: "link",
                                    permission: "view_customised_sop"
                                },
                                {
                                    path: "/reports/fmmgc/chemical-knowledge",
                                    title: "Chemical Knowledge",
                                    type: "link",
                                    permission: "view_chemical_knowledge"
                                },
                                {
                                    path: "/reports/fmmgc/basic-hk-knowledge",
                                    title: "Basic HK Knowledge",
                                    type: "link",
                                    permission: "view_basic_hk_knowledge"
                                },
                                {
                                    path: "/reports/fmmgc/machine-knowledge",
                                    title: "Machine Knowledge",
                                    type: "link",
                                    permission: "view_machine_knowledge"
                                }
                            ]
                        },
                        {
                            title: "Uniform",
                            type: "sub",
                            active: false,
                            permission: "view_uniform_reports",
                            children: [
                                {
                                    path: "/reports/uniform/return-report",
                                    title: "Return Report",
                                    type: "link",
                                    permission: "view_return_report"
                                },
                                {
                                    path: "/reports/uniform/laundry-report",
                                    title: "Laundry Report",
                                    type: "link",
                                    permission: "view_laundry_report"
                                }
                            ]
                        },
                        {
                            title: "Operation Reports",
                            type: "sub",
                            active: false,
                            permission: "view_reports",
                            children: [
                                {
                                    path: "/reports/operation-reports/exchange",
                                    title: "Exchange Report",
                                    type: "link",
                                    permission: "view_reports"
                                },
                                {
                                    path: "/reports/operation-reports/transfer",
                                    title: "Transfer Report",
                                    type: "link",
                                    permission: "view_reports"
                                }
                            ]
                        },
                        {
                            title: "Marketing Reports",
                            type: "sub",
                            active: false,
                            permission: "view_marketing_reports",
                            children: [
                                {
                                    path: "/reports/percentage",
                                    title: "Percentage analysis",
                                    type: "link",
                                    permission: "view_percentage_analysis"
                                },
                                {
                                    path: "/reports/area",
                                    title: "Area analysis",
                                    type: "link",
                                    permission: "view_area_analysis"
                                },
                                {
                                    path: "/reports/marketing-kyt-change-transfer",
                                    title: "Marketing KYT Change Transfer",
                                    type: "link",
                                    permission: "view_reports"
                                },
                                {
                                    path: "/reports/criteria",
                                    title: "Criteria",
                                    type: "link",
                                    permission: "view_criteria"
                                },

                                {
                                    path: "/reports/project",
                                    title: "Project report",
                                    type: "link",
                                    permission: "view_project_report"
                                },
                                {
                                    path: "/reports/soc",
                                    title: "SOC analysis",
                                    type: "link",
                                    permission: "view_soc_analysis"
                                },

                                {
                                    path: "/reports/competitor",
                                    title: "Competitor analysis",
                                    type: "link",
                                    permission: "view_competitor_analysis"
                                },

                                {
                                    path: "/reports/project-with",
                                    title: "Project with",
                                    type: "link",
                                    permission: "view_project_with"
                                },
                                {
                                    path: "/reports/projection",
                                    title: "Projection and status",
                                    type: "link",
                                    permission: "view_projection_status"
                                },
                                {
                                    path: "/reports/just-analysis",
                                    title: "Just analysis",
                                    type: "link",
                                    permission: "view_just_analysis"
                                },

                                {
                                    path: "/reports/no-of-project-converted",
                                    title: "Project Converted",
                                    type: "link",
                                    permission: "view_project_converted"
                                },
                                {
                                    path: "/reports/assign-work",
                                    title: "Assign Work",
                                    type: "link",
                                    permission: "view_marketing_reports"
                                },
                                {
                                    path: "/reports/cdw-given",
                                    title: "Cdw Given",
                                    type: "link",
                                    permission: "view_reports"
                                },

                                {
                                    path: "/reports/quotations-proposal",
                                    title: "Quotations Proposal",
                                    type: "link",
                                    permission: "view_quotations_proposal"
                                },
                            ]
                        },
                        {
                            path: "/reports/return-documents",
                            title: "Return Documents",
                            type: "link",
                            permission: "view_return_documents"
                        },
                        {
                            path: "/reports/stock",
                            title: "Stock Report",
                            type: "link",
                            permission: "view_stock_report"
                        },

                        {
                            path: "/reports/training-briefing",
                            title: "Training and briefing",
                            type: "link",
                            permission: "view_training_briefing"
                        }
                    ]
                },
                {
                    path: "/marketing-kyt-approval/list",
                    title: "Marketing KYT Approval",
                    icon: "clipboard",
                    type: "link",
                    badge: true
                },
                {
                    path: "/ecscalation/list",
                    title: "Escalation",
                    type: "link",
                    // permission: "view_scalation"
                },
                {
                    title: "Operation",
                    icon: "box",
                    type: "sub",
                    active: false,
                    permission: "view_operation",
                    children: [
                        {
                            path: "/operation/transfer",
                            title: "Transfer",
                            type: "link",
                            permission: "view_transfer"
                        },
                        {
                            path: "/operation/exchange",
                            title: "Exchange",
                            type: "link",
                            permission: "view_exchange"
                        },
                        {
                            path: "/operation/kyp",
                            title: "KYP",
                            type: "link",
                            permission: "view_kyp"
                        },
                        {
                            path: "/operation/advance-salary-request",
                            title: "Advance Salary Request",
                            type: "link",
                            permission: "view_advance_salary"
                        },
                        {
                            path: "/operation/reinstate",
                            title: "Reinstate",
                            type: "link",
                            permission: "view_reinstate"
                        },
                        {
                            path: "/operation/siddar",
                            title: "SIDDAR",
                            type: "link",
                            permission: "view_siddar"
                        },
                        {
                            path: "/operation/frequency",
                            title: "Frequency",
                            type: "link",
                            permission: "view_frequency"
                        },
                        {
                            path: "/operation/manpower-contribution",
                            title: "Manpower Contribution",
                            type: "link",
                            permission: "view_manpower_contribution"
                        },
                        {
                            path: "/cdw/list",
                            title: "CDW",
                            type: "link",
                            permission: "view_cdw"
                        },
                        {
                            path: "/operation/reliver-taken",
                            title: "Reliver Taken",
                            type: "link",
                            permission: "view_reliver_taken"
                        },
                        {
                            path: "/operation/marketing-lead",
                            title: "Marketing Lead",
                            type: "link",
                            permission: "view_marketing_lead"
                        },
                        {
                            path: "/operation/material-request",
                            title: "Material Request",
                            type: "link",
                            permission: "view_material_request"
                        }
                    ]
                },
                {
                    title: "Front Office",
                    icon: "monitor",
                    type: "sub",
                    active: false,
                    permission: "view_front_office",
                    children: [
                        {
                            path: "/front-office/pre-interview",
                            title: "Pre Interview",
                            type: "link",
                            permission: "view_pre_interview"
                        },
                        {
                            path: "/front-office/interview",
                            title: "Interview Panel 1",
                            type: "link",
                            permission: "view_interview",
                        },
                        {
                            path: "/front-office/interview/panel-2-list",
                            title: "Interview Panel 2",
                            type: "link",
                            permission: "view_interview_panel_2"
                        },
                        {
                            path: "/front-office/joining",
                            title: "Joining",
                            type: "link",
                            permission: "view_joining"
                        }
                    ]
                },
                {
                    title: "Uniform",
                    icon: "monitor",
                    type: "sub",
                    active: false,
                    permission: "view_uniform",
                    children: [
                        {
                            path: "/uniform/return",
                            title: "Return Uniform",
                            type: "link",
                            permission: "view_return_uniform"
                        },
                        {
                            path: "/uniform/issue",
                            title: "Issue Uniform",
                            type: "link",
                            permission: "view_issue_uniform"
                        }
                    ]
                },
                {
                    title: "Stocks",
                    icon: "box",
                    type: "sub",
                    active: false,
                    permission: "view_stocks",
                    children: [
                        {
                            path: "/stocks/out-of-stocks",
                            title: "Out of Stocks",
                            type: "link",
                            permission: "view_out_of_stocks"
                        }
                    ]
                },
                {
                    title: "Docs",
                    path: "/docs/list",
                    type: "link",
                    permission: "view_docs"
                },
                {
                    title: "Return Docs",
                    path: "/docs/return",
                    type: "link",
                    permission: "view_return_docs"
                },
                {
                    title: "PV Details",
                    path: "/pv-details/list",
                    type: "link",
                    permission: "view_pv_details"
                },
                {
                    title: "PF/ESIC Details",
                    path: "/pf-esic-details/list",
                    type: "link",
                    permission: "view_pf_esic_details"
                },
                {
                    title: "Variation Chart",
                    path: "/variation-chart/list",
                    type: "link",
                    permission: "view_variation_chart"
                },
                {
                    title: "Pending Docs",
                    path: "/pages/pending-docs",
                    type: "link",
                    permission: "view_pending_docs"
                },
                {
                    title: "Uniform Account",
                    path: "/uniform-account/list",
                    type: "link",
                    permission: "view_uniform_account"
                },
                {
                    title: "Vendor",
                    path: "/vendor/list",
                    type: "link",
                    permission: "view_vendor"
                },
                {
                    title: "Site Creation",
                    path: "/site-creation/list",
                    type: "link",
                    permission: "view_site_creation"
                },
                {
                    title: "New Sites",
                    path: "/new-sites/list",
                    type: "link",
                    permission: "view_new_sites"
                },
                {
                    title: "Joining Finalization",
                    path: "/joining-finalization/list",
                    type: "link",
                    permission: "view_joining_finalization"
                },
                {
                    title: "Deduction Sheet",
                    path: "/deduction-sheet/list",
                    type: "link",
                    permission: "view_deduction_sheet"
                },
                {
                    title: "Chase List",
                    path: "/chase-list/list",
                    type: "link",
                    permission: "view_chase_list"
                },
                {
                    title: "Incentives",
                    path: "/incentives/list",
                    type: "link",
                    permission: "view_incentives"
                },
                {
                    title: "Products",
                    path: "/products/list",
                    type: "link",
                    permission: "view_products"
                },
                {
                    title: "Attendance",
                    path: "/attendance/list",
                    type: "link",
                    permission: "view_attendance"
                },
                {
                    title: "Late Attendance",
                    path: "/attendance/late-attendance",
                    type: "link",
                    permission: "view_attendance"
                },
                {
                    title: "Employee Late Claim",
                    path: "/attendance/employee-late-claim",
                    type: "link",
                    permission: "view_attendance"
                },
                {
                    title: "Materials",
                    icon: "box",
                    type: "sub",
                    active: false,
                    permission: "view_materials",
                    children: [
                        {
                            path: "/materials/orders",
                            title: "Orders",
                            type: "link",
                            permission: "view_orders"
                        },
                        {
                            path: "/materials/goods",
                            title: "Goods",
                            type: "link",
                            permission: "view_goods"
                        }
                    ]
                },
                {
                    title: "Settings",
                    icon: "settings",
                    type: "sub",
                    active: false,
                    permission: "view_settings",
                    children: [
                        {
                            title: "Services",
                            path: "/settings/services",
                            type: "link",
                            permission: "view_services_settings"
                        },
                        {
                            title: "Designation",
                            path: "/settings/designation",
                            type: "link",
                            permission: "view_settings"
                        },
                        {
                            title: "Areas",
                            path: "/settings/areas",
                            type: "link",
                            permission: "view_area_management"
                        },
                        {
                            title: "Roles",
                            path: "/permissions/roles",
                            type: "link",
                            permission: "view_roles"

                        },
                        {
                            title: "Competitor",
                            path: "/settings/competitor",
                            type: "link",
                            // permission: "view_competitor"
                        },
                        {
                            title: "Interview Questions",
                            path: "/interview-questions/list",
                            type: "link",
                            permission: "view_interview_questions"
                        },
                        {
                            title: "Department",
                            path: "/settings/department",
                            type: "link",
                            permission: "view_department"
                        },
                        {
                            title: "General Settings",
                            path: "/general-settings/list",
                            type: "link",
                            permission: "view_general_settings"
                        },
                        {
                            title: "Joining",
                            type: "sub",
                            active: false,
                            permission: "view_joining_settings",
                            children: [
                                {
                                    path: "/joining/assigning-panel",
                                    title: "Assigning Panel",
                                    type: "link",
                                    permission: "view_assigning_panel"
                                },
                                {
                                    path: "/joining/site-joining",
                                    title: "Site Joining",
                                    type: "link",
                                    permission: "view_site_joining"
                                }
                            ]
                        },
                        {
                            path: "/front-office/interview",
                            title: "Interview",
                            type: "link",
                            permission: "view_interview_settings"
                        },
                        {
                            path: "/category/list",
                            title: "Category list",
                            type: "link",
                            permission: "view_category_list"
                        },
                        {
                            path: "/category/subList",
                            title: "Sub category list",
                            type: "link",
                            permission: "view_subcategory_list"
                        },

                    ]
                },

            ]
        }
    ]
};

// Filterthe menu data based on permissions
export const Menu: MenuData = {
    data: rawMenu.data.map(section => ({
        ...section,
        children: filterMenuItems(section.children)
    }))
};

export default Menu;