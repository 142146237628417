<template>
    <div class="site-list">
        <!-- Header with Create Button -->
        <div class="d-flex justify-content-between align-items-center mb-4">
            <h2>New Sites</h2>
            <!-- <button class="btn btn-primary" @click="createNewSite">
                <i class="bi bi-plus-lg"></i> Create New Site
            </button> -->
        </div>

        <!-- Search and Filter Section -->
        <div class="card mb-4">
            <div class="card-body">
                <div class="row g-3">
                    <div class="col-md-4">
                        <input type="text" class="form-control" v-model="searchQuery" placeholder="Search sites...">
                    </div>
                    <div class="col-md-3">
                        <select class="form-select" v-model="filterStatus">
                            <option value="">All Status</option>
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>

        <!-- Sites Grid -->
        <div class="row row-cols-1 row-cols-md-2 row-cols-xl-3 g-4">
            <div v-for="site in filteredSites" :key="site._id" class="col">
                <div class="card h-100" @click="handleCardClick($event, site)">
                    <div class="card-body">
                        <h5 class="card-title mb-3">{{ site.siteName }}</h5>
                        <div class="site-details">
                            <p><strong>Site UID:</strong> {{ site.siteUID }}</p>
                            <p><i class="bi bi-geo-alt"></i> {{ site.siteAddress }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Loading State -->
        <div v-if="isLoading" class="text-center my-4">
            <div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
            </div>
        </div>

        <!-- Empty State -->
        <div v-if="!isLoading && filteredSites.length === 0" class="text-center my-4">
            <p class="text-muted">No sites found</p>
        </div>
    </div>

    <!-- Site Details Modal -->
    <div v-if="showModal" class="modal-wrapper">
        <div class="modal fade show" tabindex="-1" style="display: block;">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Site Details</h5>
                        <button type="button" class="btn-close" @click="closeModal"></button>
                    </div>
                    <div class="modal-body" v-if="selectedSite">
                        <!-- Basic Info Section -->
                        <div class="row">
                            <div class="col-md-6">
                                <h6>Basic Information</h6>
                                <p><strong>Site Name:</strong> {{ selectedSite.siteName }}</p>
                                <p><strong>Site UID:</strong> {{ selectedSite.siteUID }}</p>
                                <p><strong>Status:</strong>
                                    <span :class="getStatusBadgeClass(selectedSite.status)">
                                        {{ selectedSite.status }}
                                    </span>
                                </p>
                                <p><strong>Address:</strong> {{ selectedSite.siteAddress }}</p>
                                <p><strong>Website:</strong> {{ selectedSite.websiteUrl || 'N/A' }}</p>
                                <p><strong>Area:</strong> {{ selectedSite.area }}</p>
                                <p><strong>Location:</strong> {{ selectedSite.siteLat }}, {{ selectedSite.siteLong }}
                                </p>
                                <p><strong>Created:</strong> {{ new Date(selectedSite.createdAt).toLocaleString() }}</p>
                                <p><strong>Updated:</strong> {{ new Date(selectedSite.updatedAt).toLocaleString() }}</p>
                            </div>
                            <div class="col-md-6">
                                <h6>Contact Person</h6>
                                <p><strong>Name:</strong> {{ selectedSite.contactPerson.name }}</p>
                                <p><strong>Designation:</strong> {{ selectedSite.contactPerson.designation }}</p>
                                <p><strong>Email:</strong> {{ selectedSite.contactPerson.email }}</p>
                            </div>
                        </div>

                        <!-- Workforce Section -->
                        <div class="row mt-4">
                            <div class="col-12">
                                <h6>Workforce Information</h6>
                                <p v-if="selectedSite.workforce.totalManpower">
                                    <strong>Total Manpower:</strong> {{ selectedSite.workforce.totalManpower }}
                                </p>
                                <p v-if="selectedSite.workforce.designations?.length">
                                    <strong>Designations:</strong> {{ selectedSite.workforce.designations.join(', ') }}
                                </p>
                            </div>
                        </div>

                        <!-- SOC Report Section -->
                        <div class="row mt-4" v-if="selectedSite.kytData?.socReport?.length">
                            <div class="col-12">
                                <h6>SOC Reports</h6>
                                <div v-for="(socGroup, groupIndex) in selectedSite.kytData.socReport" :key="groupIndex">
                                    <div v-for="(soc, socIndex) in socGroup" :key="socIndex" class="card mb-3">
                                        <div class="card-body">
                                            <p><strong>Service:</strong> {{ soc.service }}</p>
                                            <p><strong>Mode of Service:</strong> {{ soc.modeOfService }}</p>
                                            <p><strong>Competitor:</strong> {{ soc.competitorName }}</p>
                                            <p><strong>Total Manpower:</strong> {{ soc.totalManpower }}</p>
                                            <p v-if="soc.socDetails?.[0]"><strong>Work Duration:</strong> {{ soc.socDetails[0].workDuration }} hours</p>
                                            <p v-if="soc.socDetails?.[0]"><strong>Salary:</strong> ₹{{ soc.socDetails[0].salary }}</p>
                                            <p><strong>Agreement Period:</strong> {{ soc.agreementStarted }} to {{ soc.agreementEnded }}</p>
                                            <div class="mt-2">
                                                <p class="mb-1"><strong>Provisions:</strong></p>
                                                <ul class="list-unstyled">
                                                    <li v-if="soc.socDetails?.[0]"><i class="bi"
                                                        :class="soc.socDetails[0].isFoodProvided ? 'bi-check-circle-fill text-success' : 'bi-x-circle-fill text-danger'"></i>
                                                        Food</li>
                                                    <li v-if="soc.socDetails?.[0]"><i class="bi"
                                                        :class="soc.socDetails[0].isStayProvided ? 'bi-check-circle-fill text-success' : 'bi-x-circle-fill text-danger'"></i>
                                                        Stay</li>
                                                    <li v-if="soc.socDetails?.[0]"><i class="bi"
                                                        :class="soc.socDetails[0].isEsicPfProvided ? 'bi-check-circle-fill text-success' : 'bi-x-circle-fill text-danger'"></i>
                                                        ESIC/PF</li>
                                                    <li v-if="soc.socDetails?.[0]"><i class="bi"
                                                        :class="soc.socDetails[0].isWeeklyOff ? 'bi-check-circle-fill text-success' : 'bi-x-circle-fill text-danger'"></i>
                                                        Weekly Off</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Requirements Section -->
                        <div class="row mt-4" v-if="selectedSite.kytData?.requirements?.length">
                            <div class="col-12">
                                <h6>Requirements</h6>
                                <div v-for="(reqGroup, groupIndex) in selectedSite.kytData.requirements"
                                    :key="groupIndex">
                                    <div v-for="(req, reqIndex) in reqGroup" :key="reqIndex" class="card mb-3">
                                        <div class="card-body">
                                            <div v-if="req.gender">
                                                <p><strong>Gender Distribution:</strong></p>
                                                <ul>
                                                    <li>Male: {{ req.gender.male }}</li>
                                                    <li>Female: {{ req.gender.female }}</li>
                                                </ul>
                                            </div>
                                            <p v-if="req.serviceName"><strong>Service:</strong> {{ req.serviceName }}
                                            </p>
                                            <p v-if="req.manPower"><strong>Manpower Required:</strong> {{ req.manPower
                                                }}</p>
                                            <p v-if="req.workingHours"><strong>Working Hours:</strong> {{
                                                req.workingHours }}</p>
                                            <div class="mt-2">
                                                <p class="mb-1"><strong>Provisions:</strong></p>
                                                <ul class="list-unstyled">
                                                    <li v-if="req.food"><i
                                                            class="bi bi-check-circle-fill text-success"></i> Food</li>
                                                    <li v-if="req.stay"><i
                                                            class="bi bi-check-circle-fill text-success"></i> Stay</li>
                                                    <li v-if="req.epf"><i
                                                            class="bi bi-check-circle-fill text-success"></i> EPF</li>
                                                    <li v-if="req.weeklyOff"><i
                                                            class="bi bi-check-circle-fill text-success"></i> Weekly Off
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- KYT Data Section -->
                        <div class="row mt-3" v-if="selectedSite.kytData">
                            <div class="col-12">
                                <h6>KYT Data</h6>
                                <!-- Follow Ups -->
                                <div v-if="selectedSite.kytData.followUps.length" class="mb-3">
                                    <strong>Follow Ups:</strong>
                                    <div v-for="(followUp, index) in selectedSite.kytData.followUps" :key="index"
                                        class="card mb-2">
                                        <div class="card-body">
                                            <p><strong>Type:</strong> {{ followUp.isCall ? 'Call' : 'Visit' }}</p>
                                            <p><strong>Next Follow-up Date:</strong> {{ new
                                                Date(followUp.NFD).toLocaleString() }}</p>
                                            <p><strong>Rating:</strong> {{ followUp.rating }}%</p>
                                        </div>
                                    </div>
                                </div>
                                <!-- Meeting Reports -->
                                <div v-if="selectedSite.kytData.meetingReport.length">
                                    <strong>Meeting Reports:</strong>
                                    <ul>
                                        <li v-for="(report, index) in selectedSite.kytData.meetingReport" :key="index">
                                            {{ report }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" @click="closeModal">Close</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="modal-backdrop fade show"></div>
    </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useRouter } from 'vue-router';
import { useToast } from 'vue-toast-notification';
import Swal from 'sweetalert2';
import { fetchGetApi, fetchPostApi } from "@/util/api";

interface Quotation {
    type: string;
    file: string;
}

interface WorkOrder {
    type: string;
    file: string;
}

interface SubDesignationDetail {
    count: number;
    requirements: string;
    _id: string;
}

interface Workforce {
    totalManpower?: number;
    workingPlace?: string;
    designations: string[];
    subDesignationDetails?: {
        [key: string]: SubDesignationDetail;
    };
}

interface Client {
    name: string;
    designation: string;
    phone: string;
    altPhone?: string;
    email: string;
    _id: string;
}

interface SiteManager {
    name: string;
    _id: string;
}

interface Department {
    managerId: string[]; DepartmentId: string[];
    workers: string[];
    _id: string;
}

interface Site {
    _id: string;
    siteName: string;
    status: 'active' | 'inactive';
    client?: Client;
    siteAddress: string;
    officeAddress?: string;
    workforce: Workforce;
    quotation?: Quotation;
    workOrder?: WorkOrder;
    workOrders: string[];
    siteUID: string;
    websiteUrl?: string;
    siteLat: number;
    siteLong: number;
    contactPerson: {
        name: string;
        designation: string;
        email: string;
        _id: string;
    };
    Department: Department[];
    kytData?: {
        followUps: any[];
        socReport: any[];
        meetingReport: string[];
        requirements: any[];
    };
}

const router = useRouter();
const isLoading = ref(false);
const searchQuery = ref('');
const filterStatus = ref('');
const toast = useToast();

const baseUrl = process.env.VUE_APP_BASE_URL;

// Dummy data
const sites = ref<Site[]>([]);

// Replace fetchSites with dummy data loader
const fetchSites = async () => {
    isLoading.value = true;
    try {
        const response = await fetchGetApi('api/temp/sites/new');
        if (response && response.data.success) {
            sites.value = response.data.data.sites;
        } else {
            console.error('Error in response:', response);
        }
    } catch (error) {
        console.error('Error fetching sites:', error);
    } finally {
        isLoading.value = false;
    }
};

// Filter and search computed property
const filteredSites = computed(() => {
    return sites.value.filter(site => {
        const matchesSearch = site.siteName.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
            site.client?.name.toLowerCase().includes(searchQuery.value.toLowerCase());
        const matchesStatus = !filterStatus.value || site.status === filterStatus.value;
        return matchesSearch && matchesStatus;
    });
});

const getStatusBadgeClass = (status: string) => {
    return {
        'badge rounded-pill': true,
        'bg-success': status === 'active',
        'bg-secondary': status === 'inactive'
    };
};

const viewSiteDetails = (siteId: string) => {
    router.push(`/sites/${siteId}`);
};

const editSite = (siteId: string) => {
    router.push(`/sites/${siteId}/edit`);
};

const createNewSite = () => {
    router.push('/site-creation/create');
};

const addSiteManager = (siteId: string) => {
    router.push(`/site-creation/add-site-manager/${siteId}`);
};

const addEmployee = (siteId: string) => {
    router.push(`/site-creation/add-employee/${siteId}`);
};

const selectedSite = ref<Site | null>(null);
const showModal = ref(false);

function handleCardClick(event: MouseEvent, site: Site) {
    const target = event.target as HTMLElement;
    if (target.closest('.card-footer') || target.closest('.dropdown')) {
        return;
    }
    openSiteDetails(site);
}

function openSiteDetails(site: Site) {
    selectedSite.value = site;
    showModal.value = true;
    document.body.style.overflow = 'hidden';
}

function closeModal() {
    showModal.value = false;
    selectedSite.value = null;
    document.body.style.overflow = '';
}

async function updateSiteStatus(siteId: string, newStatus: 'active' | 'inactive') {
    try {
        const result = await Swal.fire({
            title: 'Are you sure?',
            text: `Do you want to mark this site as ${newStatus}?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes',
            cancelButtonText: 'Cancel'
        });

        if (result.isConfirmed) {
            const response = await fetchPostApi(`api/temp/update/site/status/${siteId}`, {
                status: newStatus
            });

            if (response && response.data.success) {
                sites.value = sites.value.map(site => {
                    if (site._id === siteId) {
                        return {
                            ...site,
                            status: newStatus
                        };
                    }
                    return site;
                });

                toast.success(`Site status updated to ${newStatus}`);
            } else {
                toast.error(response.data.message || 'Failed to update site status');
            }
        }
    } catch (error) {
        console.error('Error updating site status:', error);
        toast.error('Failed to update site status');
    }
}

// Fetch sites when component is mounted
fetchSites();
</script>

<style scoped>
.site-list {
    padding: 20px;
}

.site-details {
    font-size: 0.9rem;
}

.site-details p {
    margin-bottom: 0.5rem;
    color: #6c757d;
}

.site-details i {
    margin-right: 0.5rem;
    color: #6c757d;
}

.card {
    transition: transform 0.2s;
    cursor: pointer;
    height: 100%;
}

.card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.badge {
    font-size: 0.8rem;
    padding: 0.5em 1em;
}

.modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1050;
}

.modal-wrapper .modal {
    background-color: rgba(0, 0, 0, 0.5);
}

.modal-wrapper .modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1;
}

/* Add styles to prevent dropdown click issues */
.dropdown-toggle,
.btn {
    position: relative;
    z-index: 1;
}

/* Ensure dropdown menu appears above modal */
.dropdown-menu {
    z-index: 1051;
}

.modal-body {
    max-height: 80vh;
    overflow-y: auto;
}

.list-unstyled {
    padding-left: 0;
    list-style: none;
}

.list-unstyled li {
    margin-bottom: 0.5rem;
}

.list-unstyled i {
    margin-right: 0.5rem;
}

.card {
    transition: transform 0.2s;
    cursor: pointer;
}

.card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
</style>