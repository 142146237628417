import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { nextTick } from 'vue';

import BodyView from "@/layout/BodyView.vue"
import auth from "@/auth/authView.vue"
import login from "@/auth/loginPage.vue"

import home from "@/pages/pages/home.vue"
import Service from "@/pages/pages/services.vue"
import whyusPage from "@/pages/pages/whyus.vue"
import contactPage from "@/pages/pages/contact.vue"
import testimonials from "@/pages/pages/testimonials.vue"
import appsPage from "@/pages/pages/apps.vue"
import JoinUsIndex from "@/pages/pages/joinus.vue"
import clientsPage from "@/pages/pages/clients.vue"
import fileManager from "@/pages/filemaneger/fileManager.vue"
import gallery from "@/pages/pages/gallery.vue"


import indexUsers from "@/pages/users/list.vue"
import imageProcess from "@/pages/image/index.vue"

import indexFieldEmployees from "@/pages/employees/field_employee_list.vue"
import indexOpertaionEmployees from "@/pages/employees/operation_employee_list.vue"


import indexDefault from "@/pages/dashbords/indexDefault.vue"
import indexContactData from "@/pages/contactsData/indexContact.vue"
import indexRoles from "@/pages/Roles/indexRoles.vue"
import kytList from "@/pages/kyts/indexKyts.vue"
import kytList2 from "@/pages/kyts/indexKyts2.vue"
import kytDetails from "@/pages/kyts/kytDetails.vue"

import indexLeaves from "@/pages/leaves/indexLeaves.vue"
import viewOfLeave from "@/pages/leaves/indexofLeaveView.vue"


import projects from "@/pages/projects/indexEntries.vue"
import projectsDetails from "@/pages/projects/view.vue"

import cdw from "@/pages/cdw/list.vue"
import kytTransfer from "@/pages/kyts/transfer/list.vue"
import changeKyt from "@/pages/kyts/change/list.vue"
import department from "@/pages/department/list.vue"
import services from "@/pages/services/list.vue"
import category from "@/pages/category/list.vue"
import subCategory from "@/pages/category/sub_list.vue"


import percentageReport from "@/pages/reports/percentage.vue"
import areaReport from "@/pages/reports/area.vue"
import areaProjectView from "@/pages/reports/area-project-view.vue"
import areaProjectViewDetails from "@/pages/reports/area-project-view-details.vue"
import marketingKytChangeTransferReport from "@/pages/reports/marketing-kyt-change-transfer-report.vue"
import projectReport from "@/pages/reports/project-report.vue"
import kytChangeTransferReport from "@/pages/reports/kytChangeTransferReport.vue"
import criteriaReport from "@/pages/reports/criteria.vue"
import socReport from "@/pages/reports/soc-report.vue"
import competitorAnalysis from "@/pages/reports/competitior.vue"
import projectWith from "@/pages/reports/project-with.vue"
import projectionAndStatus from "@/pages/reports/projection-status.vue"
import justAnalysis from "@/pages/reports/just-analysis.vue"
import noProjectConverted from "@/pages/reports/no-of-project-converted.vue"
import cdwGiven from "@/pages/reports/cdw-given.vue"
import quotationsProposal from "@/pages/reports/quotations-proposal.vue"
import returnItemReport from "@/pages/reports/uniform/return-item-report.vue"
import laundryReport from '@/pages/reports/uniform/laundry-report.vue';
import stockReport from '@/pages/reports/stock-report.vue';
import joinUsSubmissions from "@/pages/pages/join-us-submissions.vue"
import assignWorkReport from "@/pages/reports/assign-work-report.vue"

//operation start
import kypList from "@/pages/operation/kyp/list.vue"
import exchangeTransfer from "@/pages/operation/transfer-exchange/list.vue"
import reinstate from "@/pages/operation/reinstate.vue"
import siddar from "@/pages/operation/siddar.vue"
import AdvanceSalaryRequest from "@/pages/operation/advance-salary-request.vue"
import Frequency from "@/pages/operation/frequency.vue"
import manpowerContribution from "@/pages/operation/manpower-contribution.vue"
import reliverTaken from "@/pages/operation/reliver-taken.vue"
import marketingLead from "@/pages/operation/marketing-lead.vue"
import materialRequest from "@/pages/operation/material-request.vue"

import kytFAD from "@/pages/kyts/fad-list.vue"
import failedFAD from "@/pages/kyts/fad/failed.vue"
import achivedFAD from "@/pages/kyts/fad/achived.vue"
import defferedFAD from "@/pages/kyts/fad/deffered.vue"
import transferredFAD from "@/pages/kyts/fad/transferred.vue"

import formatFmmgc from "@/pages/operation/fmmgc/format.vue"
import groomingFmmgc from "@/pages/operation/fmmgc/grooming.vue"
import machineFmmgc from "@/pages/operation/fmmgc/machine.vue"
import manpowerFmmgc from "@/pages/operation/fmmgc/manpower.vue"
import sopKnowledgeFmmgc from "@/pages/operation/fmmgc/sop-knowledge.vue"
import customisedSopFmmgc from "@/pages/operation/fmmgc/customised-sop.vue"
import chimicalKnowledgeFmmgc from "@/pages/operation/fmmgc/chemical-knowledge.vue"
import basicHkFmmgc from "@/pages/operation/fmmgc/basic-hk-knowledge.vue"
import machineKnowledgeFmmgc from "@/pages/operation/fmmgc/machine-knowledge.vue"
import trainingAndBriefing from "@/pages/operation/training/security-training.vue"
import technicalTraining from "@/pages/operation/training/technical-training.vue"

import transfer from "@/pages/operation/transfer.vue"
import exchange from "@/pages/operation/exchange.vue"
import generalSettings from "@/pages/general-settings/general-settings.vue"

//----------------------------------------------
// Front Office
//----------------------------------------------


import indexpreJoin from "@/pages/front-office/pre-interview/prejoining.vue"
import indexInterview from "@/pages/front-office/interview/interview.vue"
import indexInvoice from "@/pages/front-office/joining-slip/invoice.vue"
import indexJoiningSlip from "@/pages/front-office/joining-slip/joining-slip.vue"
import interviewProcess from "@/pages/front-office/interview/interview-process.vue"
import interviewProcessPanel2 from "@/pages/front-office/interview/interview-process-panel2.vue"
import panel2List from "@/pages/front-office/interview/panel-2-list.vue"
import joining from '@/pages/front-office/joining/joining.vue';

//Uniform imports
import returnUniform from '@/pages/uniform/return-uniform/return-uniform.vue';
import issueUniform from '@/pages/uniform/issue-uniform/issue-uniform.vue';
import issueUniformDetails from '@/components/uniform/issue-uniform/issue-uniform-details.vue';

//Stocks imports
import outOfStocks from '@/pages/stocks/out-of-stocks/out-of-stocks.vue';

//Materials imports
import orders from '@/pages/materials/orders/orders.vue';
import goods from '@/pages/materials/goods/goods.vue';

//Docs imports
import docs from '@/pages/docs/docs.vue';
import returnDocs from '@/components/docs/return-docs.vue';
import pvDetails from '@/pages/PV-details/pv-details.vue';
import pfEsicDetails from '@/pages/pf-esic-details/pf-esic-details.vue';
import assigningPanel from '@/pages/joining/assigning-panel/assigning-panel.vue';
import siteJoining from '@/pages/joining/site-joining/site-joining.vue';
import pendingDocs from '@/pages/pending-docs/pending-docs.vue';
import laundryReportPage from '@/pages/reports/laundry/laundry-report-page.vue';
import variationChart from '@/components/variation-chart/variation-chart.vue';
import returnDocument from '@/pages/documents/return-documents/return-document.vue';
import returnDocsReport from '@/pages/reports/return-documents.vue';
import uniformAccount from '@/pages/uniform-account/uniform-account.vue';
import joiningFinalization from '@/pages/joining-finalization/joining-finalization.vue';
import deductionSheet from '@/pages/deduction-sheet/deduction-sheet.vue';
import chaseList from '@/pages/chase-list/chase-list.vue';
import incentives from '@/pages/incentives/incentives.vue';
import allEmployeeList from '@/pages/employee-list/all-employee-list.vue';
import blackListedEmployee from '@/pages/employee-list/black-listed-employee.vue';
import employeeJobLife from '@/components/employee-list/all-employee-list/employee-job-life.vue';
import products from '@/pages/products/products.vue';
import interviewQuestions from '@/pages/interview-question/interview-questions.vue';
import vendor from '@/pages/vendor/vendor.vue';
import siteCreation from '@/components/site-creation/site-creation-form.vue';
import addRegister from '@/components/site-creation/add-register.vue';
import addMachine from '@/components/site-creation/add-machine.vue';
import siteList from '@/pages/site-creation/site-creation.vue';
import addSiteManager from '@/components/site-creation/add-site-manager.vue';
import addEmployee from '@/components/site-creation/add-employee.vue';
import employeeDetails from '@/components/pf-esic-details/employee-details.vue';
import fieldEmployeeDay from '@/pages/field-employee-day/field-employee-day.vue';
import { hasPermission } from '@/util/permissionCheck';
import error403 from '@/pages/403-page/403.vue';
import activityLog from '@/pages/activitylog/log.vue';
import newSites from '@/components/visit-ent/site-list.vue';
import projectLifeReport from '@/pages/project-life-report/project-life-report.vue';
import rateChart from '@/pages/ratechart/index.vue';
import areas from '@/pages/settings/areas.vue';
import attendance from '@/pages/attendance/list.vue';
import lateAttendance from '@/pages/attendance/late-attendance.vue';
import employeeLateClaim from '@/pages/attendance/employee-late-claim.vue';
import assignMarketingWork from '@/pages/assign-marketing-work/assign-marketing-work.vue';

import quotation from '@/pages/quotation/index.vue';
import appQuoats from '@/components/appQuoats/list.vue';

import projectReportDirect from "@/pages/reports/project-report-direct.vue"
import designation from "@/pages/designation/designation.vue"
import assignWorkApproval from '@/pages/assign-work-approval/assign-work-approval.vue';
import marketingKYTApproval from '@/pages/reports/marketing-kyt-approval.vue';
import ecscalation from '@/pages/ecscalation/list.vue';
import lockedEmployees from '@/pages/locked-employees/locked-employees.vue';
import updateWorks from '@/components/locked-employees/update-works.vue';
import exchangeReport from '@/pages/reports/operation-reports/exchange-report.vue';
import transferReport from '@/pages/reports/operation-reports/transfer-report.vue';
import materialRequestReport from '@/pages/reports/operation-reports/material-request-report.vue';
import competitor from '@/pages/settings/competitor.vue';
import importEmployee from '@/pages/import-employee/import-employee.vue';

// Define the custom meta type
interface CustomMeta {
  title?: string;
  requiresAuth?: boolean;
  permission?: string;
}

// Create a type rather than an interface
type CustomRouteRecord = RouteRecordRaw & {
  meta?: CustomMeta;
  children?: CustomRouteRecord[];
}

const routes: CustomRouteRecord[] = [

  {
    path: "/",
    component: BodyView,
    meta: {
      permission: "view_dashboard"
    },
    children: [
      {
        path: "",
        name: "Dashboard",
        component: indexDefault,
        meta: {
          title: 'Dashboard',
          permission: "view_dashboard"
        }
      }
    ]
  },
  {
    path: "/auth",
    component: auth,
    children: [
      {
        path: "login",
        name: "login",
        component: login
      }
    ]
  },

  {
    path: "/pages",
    component: BodyView,
    meta: {
      permission: "view_website"
    },
    children: [
      {
        path: "home",
        name: "Home",
        component: home,
        meta: {
          title: 'Home Page',
          permission: "view_home"
        }
      },
      {
        path: "services-page",
        name: "Services Page",
        component: Service,
        meta: {
          title: 'Services Page',
          permission: "view_services"
        }
      },
      {
        path: "whyus",
        name: "WhyUs",
        component: whyusPage,
        meta: {
          title: 'Why Us Page',
          permission: "view_whyus"
        }
      },
      {
        path: "gallery",
        name: "Gallery",
        component: gallery,
        meta: {
          title: 'Gallery Page',
          permission: "view_gallery"
        }
      },
      {
        path: "contact-us",
        name: "ContactUs",
        component: contactPage,
        meta: {
          title: 'Contact Us Page',
          permission: "view_contact"
        }
      },
      {
        path: "pending-docs",
        name: "Pending Docs",
        component: pendingDocs,
        meta: {
          title: 'Pending Docs',
          permission: "view_pending_docs"
        }
      },
      {
        path: "testimonials",
        name: "Testimonials",
        component: testimonials,
        meta: {
          title: 'Testimonials Page',
          permission: "view_testimonials"
        }
      },
      {
        path: "apps",
        name: "Apps",
        component: appsPage,
        meta: {
          title: 'Apps Page',
          permission: "view_apps"
        }
      },
      {
        path: "join-us",
        name: "JoinUs",
        component: JoinUsIndex,
        meta: {
          title: 'Join Us Page',
          permission: "view_joinus"
        }
      },
      {
        path: "join-us-submissions",
        name: "Join Us Submissions",
        component: joinUsSubmissions,
        meta: {
          title: 'Join Us Submissions',
          permission: "view_joinforms"
        }
      },
      {
        path: "clients",
        name: "Clients",
        component: clientsPage,
        meta: {
          title: 'Clients Page',
          permission: "view_clients"
        }
      }
    ]
  },

  {
    path: "/dashboards",
    component: BodyView,
    children: [
      {
        path: "default",
        name: "default",
        component: indexDefault,
        meta: {
          title: 'Dashboard ',
        }
      }
    ]
  },
  {
    path: "/ecscalation",
    component: BodyView,
    children: [
      {
        path: "list",
        name: "EC Scalation",
        component: ecscalation,
        meta: {
          title: 'EC Scalation',
        }
      }
    ]
  },
  {
    path: "/joining",
    component: BodyView,
    children: [
      {
        path: "assigning-panel",
        name: "Assigning Panel",
        component: assigningPanel,
        meta: {
          title: 'Assigning Panel',
        }
      },
      {
        path: "site-joining",
        name: "Site Joining",
        component: siteJoining,
        meta: {
          title: 'Site Joining',
        }
      },
    ]
  },
  {
    path: "/general-settings",
    component: BodyView,
    children: [
      {
        path: "list",
        name: "General Settings ",
        component: generalSettings,
        meta: {
          title: 'General Settings',
        }
      }
    ]
  },
  {
    path: "/users",
    component: BodyView,
    meta: {
      permission: "view_users"
    },
    children: [
      {
        path: "list",
        name: "Users",
        component: indexUsers,
        meta: {
          title: 'Users List',
          permission: "view_users"
        }
      }
    ]
  }, {
    path: "/image",
    component: BodyView,
    children: [
      {
        path: "processing",
        name: "Image Processing",
        component: imageProcess,
        meta: {
          title: 'Image Processing',
        }
      },

    ]
  },
  {
    path: "/ratechart",
    component: BodyView,
    children: [
      {
        path: "list",
        name: "Rate Charts",
        component: rateChart,
        meta: {
          title: 'Rate Charts',
        }
      }
    ]
  },
  {
    path: "/attendance",
    component: BodyView,
    children: [
      {
        path: "list",
        name: "Attendance",
        component: attendance,
        meta: {
          title: 'Attendance',
        }
      },
      {
        path: "late-attendance",
        name: "Late Attendance",
        component: lateAttendance,
        meta: {
          title: 'Late Attendance',
        }
      },
      {
        path: "employee-late-claim",
        name: "Employee Late Claim",
        component: employeeLateClaim,
        meta: {
          title: 'Employee Late Claim',
        }
      }
    ]
  },
  {
    path: "/locked-employees",
    component: BodyView,
    children: [
      {
        path: "list",
        name: "Locked Employees",
        component: lockedEmployees,
        meta: {
          title: 'Locked Employees',
        }
      },
      {
        path: "update-works/:employeeId",
        name: "Update Works",
        component: updateWorks,
        meta: {
          title: 'Update Works',
        }
      }
    ]
  },

  {
    path: "/activity-log",
    component: BodyView,
    children: [
      {
        path: "log",
        name: "Activity Log",
        component: activityLog,
        meta: {
          title: 'Activity Log',
        }
      }
    ]
  },

  {
    path: "/employees",
    component: BodyView,
    meta: {
      permission: "view_employees"
    },
    children: [
      {
        path: "field",
        name: "Field Employees",
        component: indexFieldEmployees,
        meta: {
          title: 'Field Employees',
          permission: "view_field_employees"
        }
      },
      {
        path: "operation",
        name: "Operation Employees",
        component: indexOpertaionEmployees,
        meta: {
          title: 'Operation Employees',
          permission: "view_operation_employees"
        }
      }
    ]
  },
  {
    path: "/permissions",
    component: BodyView,
    meta: {
      permission: "view_permissions"
    },
    children: [
      {
        path: "roles",
        name: "Roles",
        component: indexRoles,
        meta: {
          title: 'Roles',
          permission: "view_roles"
        }
      }
    ]
  },
  {
    path: "/leaves",
    component: BodyView,
    children: [
      {
        path: "list",
        name: "Leaves",
        component: indexLeaves,
        meta: {
          title: 'Leaves',
        }
      },
      {
        path: "view/:id",
        name: "Leave Detail",
        component: viewOfLeave,
        meta: {
          title: 'Leave Detail',
        }
      }
    ]
  },
  {
    path: "/app",
    component: BodyView,
    children: [
      {
        path: "file_manager",
        name: "fileManager",
        component: fileManager,
        meta: {
          title: 'File Manager',
        }
      },

      {
        path: "contacts_data",
        name: "Contacts Form Data",
        component: indexContactData,
        meta: {
          title: 'Contacts Form Data',
        }
      },

    ]
  },
  {
    path: "/kyts",
    component: BodyView,
    meta: {
      permission: "view_kyt"
    },
    children: [
      {
        path: "operation",
        name: "Operation Kyt",
        component: kytList2,
        meta: {
          title: 'Operation Kyt',
          permission: "view_operation_kyt"
        }
      },
      {
        path: "marketing",
        name: "Marketing Kyt",
        component: kytList,
        meta: {
          title: 'Marketing Kyt',
          permission: "view_marketing_kyt"
        }
      },
      {
        path: "view/:id",
        name: "KYT Details",
        component: kytDetails,
        meta: {
          title: 'Kyt Details',
        }
      },
      {
        path: "transfer/request",
        name: "Transfer Kyt",
        component: kytTransfer,
        meta: {
          title: 'Transfer Kyt',
          permission: "view_kyt_transfer"
        }
      },
      {
        path: "change/request",
        name: "Change Kyt",
        component: changeKyt,
        meta: {
          title: 'Change Kyt',
          permission: "view_kyt_change"
        }
      },
      {
        path: "fad",
        name: "Fad Kyt",
        component: kytFAD,
        meta: {
          title: 'Fad Kyt',
        }
      },
      {
        path: "fad/failed",
        name: "Failed Fad",
        component: failedFAD,
        meta: {
          title: 'Failed Fad',
        }
      },
      {
        path: "fad/achived",
        name: "Achived Fad",
        component: achivedFAD,
        meta: {
          title: 'Achived Fad',
        }
      },
      {
        path: "fad/deffered",
        name: "Deffered Fad",
        component: defferedFAD,
        meta: {
          title: 'Deffered Fad',
        }
      },
      {
        path: "fad/transferred",
        name: "Transferred Fad",
        component: transferredFAD,
        meta: {
          title: 'Transferred Fad',
        }
      }
    ]
  },
  {
    path: "/projects",
    component: BodyView,
    children: [
      {
        path: "list",
        name: "Projects",
        component: projects,
        meta: {
          title: 'Projects',
        }
      },
      {
        path: "view/:id",
        name: "Project Details",
        component: projectsDetails,
        meta: {
          title: 'Project Details',
        }
      }
    ]
  },
  {
    path: "/cdw",
    component: BodyView,
    children: [
      {
        path: "list",
        name: "cdw",
        component: cdw,
        meta: {
          title: 'CDW',
        }
      },
    ]
  },
  {
    path: "/department",
    component: BodyView,
    children: [
      {
        path: "",
        name: "Department",
        component: department,
        meta: {
          title: 'Department',
        }
      },
    ]
  },
  {
    path: "/services",
    component: BodyView,
    children: [
      {
        path: "",
        name: "Services",
        component: services,
        meta: {
          title: 'Services',
        }
      },
    ]
  },
  {
    path: "/category",
    component: BodyView,
    children: [
      {
        path: "list",
        name: "Category list",
        component: category,
        meta: {
          title: "Category list",
          permission: "view_category_list"
        }
      }, {
        path: "subList",
        name: "Sub Category list",
        component: subCategory,
        meta: {
          title: "Sub Category list",
          permission: "view_subcategory_list"
        }
      }
    ]
  },
  {
    path: "/reports",
    component: BodyView,
    meta: {
      permission: "view_reports"
    },
    children: [
      {
        path: "training",
        meta: {
          permission: "view_training_reports"
        },
        children: [
          {
            path: "security-training",
            name: "Security Training",
            component: trainingAndBriefing,
            meta: {
              title: 'Security Training',
              permission: "view_security_training"
            }
          },
          {
            path: "technical-training",
            name: "Technical Training",
            component: technicalTraining,
            meta: {
              title: 'Technical Training',
              permission: "view_technical_training"
            }
          }
        ]
      },
      {
        path: "operation-reports",
        children: [
          {
            path: "exchange",
            name: "Exchange Report",
            component: exchangeReport,
            meta: {
              title: 'Exchange Report'
            }
          },
          {
            path: "transfer",
            name: "Transfer Report",
            component: transferReport,
            meta: {
              title: 'Transfer Report'
            }
          }
        ]
      },
      {
        path: "fmmgc",
        children: [
          {
            path: "format",
            name: "Format Report",
            component: formatFmmgc,
            meta: {
              title: 'Format Report'
            }
          },
          {
            path: "grooming",
            name: "Grooming Report",
            component: groomingFmmgc,
            meta: {
              title: 'Grooming Report'
            }
          },
          {
            path: "machine",
            name: "Machine Report",
            component: machineFmmgc,
            meta: {
              title: 'Machine Report'
            }
          },
          {
            path: "manpower",
            name: "Manpower Report",
            component: manpowerFmmgc,
            meta:
            {
              title: 'Manpower Report'
            }
          },
          {
            path: "sop-knowledge",
            name: "SOP Knowledge Report",
            component: sopKnowledgeFmmgc,
            meta: {
              title: 'SOP Knowledge Report'
            }
          },
          {
            path: "customised-sop",
            name: "Customised SOP Report",
            component: customisedSopFmmgc,
            meta: {
              title: 'Customised SOP Report'
            }
          },
          {
            path: "chemical-knowledge",
            name: "Chemical Knowledge Report",
            component: chimicalKnowledgeFmmgc,
            meta: {
              title: 'Chemical Knowledge Report'
            }
          },
          {
            path: "basic-hk-knowledge",
            name: "Basic HK Knowledge Report",
            component: basicHkFmmgc,
            meta: {
              title: 'Basic HK Knowledge Report'
            }
          },
          {
            path: "machine-knowledge",
            name: "Machine Knowledge Report",
            component: machineKnowledgeFmmgc,

            meta: {
              title: 'Machine Knowledge Report'
            }
          }

        ]
      },
      {
        path: "uniform",
        children: [
          {
            path: "return-report",
            name: "Return Report",
            component: returnItemReport,
            meta: {
              title: 'Return Report'
            }
          },
          {
            path: "laundry-report",
            name: "Laundry Report",
            component: laundryReport,
            meta: {
              title: 'Laundry Report'
            }
          }
        ]
      },
      {
        path: "laundry",
        name: "Laundry Page Report",
        component: laundryReportPage,
        meta: {
          title: 'Laundry Page Report',
        }
      },
      {
        path: "return-documents",
        name: "Return Documents Report",
        component: returnDocsReport,
        meta: {
          title: 'Return Documents Report',
        }
      },
      {
        path: "percentage",
        name: "Percentage analysis",
        component: percentageReport,
        meta: {
          title: 'Percentage analysis',
        }
      },
      {
        path: "assign-work",
        name: "Assign Work Report",
        component: assignWorkReport,
        meta: {
          title: 'Assign Work Report',
        }
      },
      {
        path: "stock",
        name: "Stock Report",
        component: stockReport,
        meta: {
          title: 'Percentage analysis',
        }
      },
      {
        path: "area",
        name: "Area analysis",
        component: areaReport,
        meta: {
          title: 'Area analysis',
        }
      },
      {
        path: "area/area-project-view/:area",
        name: "Area Project analysis",
        component: areaProjectView,
        meta: {
          title: 'Area - Project analysis',
        }
      },
      {
        path: "area/area-project-view-details/:projectId",
        name: "Area Project analysis Details",
        component: areaProjectViewDetails,
        meta: {
          title: 'Area - Project analysis Details',
        }
      },
      {
        path: "marketing-kyt-change-transfer",
        name: "Marketing KYT Change Transfer",
        component: marketingKytChangeTransferReport,
        meta: {
          title: 'Marketing KYT Change Transfer',
        }
      },
      {
        path: "project",
        name: "Project report",
        component: projectReport,
        meta: {
          title: 'Project report',
        }
      },
      {
        path: "kyt-change-transfer",
        name: "Kyt change Transfer report",
        component: kytChangeTransferReport,
        meta: {
          title: 'Kyt Change Transfer',
        }
      },
      {
        path: "criteria",
        name: "Criteria",
        component: criteriaReport,
        meta: {
          title: 'Criteria',
        }
      },
      {
        path: "soc",
        name: "SOC analysis",
        component: socReport,
        meta: {
          title: 'SOC analysis',
        }
      },
      {
        path: "competitor",
        name: "Competitor analysis",
        component: competitorAnalysis,
        meta: {
          title: 'Competitor analysis',
        }
      },
      {
        path: "project-with",
        name: "Project With",
        component: projectWith,
        meta: {
          title: 'Project With',
        }
      },
      {
        path: "projection",
        name: "Projection and status",
        component: projectionAndStatus,
        meta: {
          title: 'Projection and status',
        }
      },
      {
        path: "just-analysis",
        name: "Just analysis",
        component: justAnalysis,
        meta: {
          title: 'JUST analysis',
        }
      },
      {
        path: "no-of-project-converted",
        name: "no-of-project-converted",
        component: noProjectConverted,
        meta: {
          title: 'Project Converted',
        }
      },
      {
        path: "cdw-given",
        name: "cdw-given",
        component: cdwGiven,
        meta: {
          title: 'Cdw Given',
        }
      },
      {
        path: "quotations-proposal",
        name: "quotations-proposal",
        component: quotationsProposal,
        meta: {
          title: 'Quotations Proposal',
          permission: "view_quotations_proposal"
        }
      },
      {
        path: "project-life-report/:siteId",
        name: "Project Life Report",
        component: projectLifeReport,
        meta: {
          title: 'Project Life Report',
        }
      },
      {
        path: "project/:areaId/:siteId",
        name: "Project Report Direct",
        component: projectReportDirect,
        meta: {
          title: 'Project Report',
        }
      }
    ]
  },
  {
    path: "/import-employee",
    component: BodyView,
    children: [
      {
        path: "add",
        name: "Import Employee",
        component: importEmployee,
        meta: {
          title: 'Import Employee',
        }
      }
    ] 
  },
  {
    path: "/operation",
    component: BodyView,
    meta: {
      permission: "view_operation"
    },
    children: [
      {
        path: "transfer",
        name: "Transfer",
        component: transfer,
        meta: {
          title: 'Transfer',
          permission: "view_transfer"
        }
      },
      {
        path: "exchange",
        name: "Exchange",
        component: exchange,
        meta: {
          title: 'Exchange',
          permission: "view_exchange"
        }
      },
      {
        path: "kyp",
        name: "Kyp List",
        component: kypList,
        meta: {
          title: 'Kyp List',
          permission: "view_kyp"
        }
      },
      {
        path: "reinstate",
        name: "Reinstate",
        component: reinstate,
        meta: {
          title: 'Reinstate',
          permission: "view_reinstate"
        }
      },
      {
        path: "siddar",
        name: "Siddar",
        component: siddar,
        meta: {
          title: 'SIDDAR',
          permission: "view_siddar"
        }
      },
      {
        path: "advance-salary-request",
        name: "Advance Salary Request",
        component: AdvanceSalaryRequest,
        meta: {
          title: 'Advance Salary Request',
          permission: "view_advance_salary"
        }
      },
      {
        path: "frequency",
        name: "Frequency",
        component: Frequency,
        meta: {
          title: 'Frequency',
          permission: "view_frequency"
        }
      },
      {
        path: "manpower-contribution",
        name: "Manpower Contribution",
        component: manpowerContribution,
        meta: {
          title: 'Manpower Contribution',
          permission: "view_manpower_contribution"
        }
      },
      {
        path: "reliver-taken",
        name: "Reliver Taken",
        component: reliverTaken,
        meta: {
          title: 'Reliver Taken',
          permission: "view_reliver_taken"
        }
      },
      {
        path: "marketing-lead",
        name: "Marketing Lead",
        component: marketingLead,
        meta: {
          title: 'Marketing Lead',
          permission: "view_marketing_lead"
        }
      },
      {
        path: "material-request",
        name: "Material Request",
        component: materialRequest,
        meta: {
          title: 'Material Request',
          permission: "view_material_request"
        }
      }
    ]
  },
  {
    path: "/front-office",
    component: BodyView,
    meta: {
      permission: "view_front_office"
    },
    children: [
      {
        path: "pre-interview",
        name: "Pre Interview",
        component: indexpreJoin,
        meta: {
          title: "Pre Interview",
          permission: "view_pre_interview"
        }
      },
      {
        path: "Interview",
        name: "Interview",
        component: indexInterview,
        meta: {
          title: "Interview",
          permission: "view_interview"
        }
      },
      {
        path: "interview/interview-process/:token",
        name: "Interview Process",
        component: interviewProcess,
        meta: {
          title: "Interview Process",
          permission: "view_interview"
        }
      },
      {
        path: "joining",
        name: "Joining",
        component: joining,
        meta: {
          title: "Joining",
          permission: "view_joining"
        }
      },
      {
        path: "interview/interview-process-panel2/:token",
        name: "Interview Panel 2",
        component: interviewProcessPanel2,
        meta: {
          title: "Interview Panel 2",
          permission: "view_interview_panel_2"
        }
      },
      {
        path: "interview/panel-2-list",
        name: "Interview Panel 2 List",
        component: panel2List,
        meta: {
          title: "Interview Panel 2",
          permission: "view_interview_panel_2"
        }
      }
    ]
  },
  {
    path: "/uniform",
    component: BodyView,
    meta: {
      permission: "view_uniform"
    },
    children: [
      {
        path: "return",
        name: "Return Uniform",
        component: returnUniform,
        meta: {
          title: "Return Uniform",
          permission: "view_return_uniform"
        }
      },
      {
        path: "issue",
        name: "Issue Uniform",
        component: issueUniform,
        meta: {
          title: "Issue Uniform",
          permission: "view_issue_uniform"
        }
      },
      {
        path: "issue/details",
        name: "Issue Uniform Details",
        component: issueUniformDetails,
        meta: {
          title: "Issue Uniform Details"
        }
      }
    ]
  },
  {
    path: "/uniform-account",
    component: BodyView,
    children: [
      {
        path: "list",
        name: "UniformAccount",
        component: uniformAccount,
        meta: {
          title: "Uniform Account",
          permission: "view_uniform_account"
        }
      }
    ]
  },
  {
    path: "/field-employee-day",
    component: BodyView,
    children: [
      {
        path: "list",
        name: "Field Employee Day",
        component: fieldEmployeeDay,
        meta: {
          title: "Field Employee Day"
        }
      }
    ]
  },
  {
    path: "/vendor",
    component: BodyView,
    children: [
      {
        path: "list",
        name: "Vendor",
        component: vendor,
        meta: {
          title: "Vendor",
          permission: "view_vendor"
        }
      }
    ]
  },
  {
    path: "/quotation",
    component: BodyView,
    children: [
      {
        path: "list",
        name: "Quotation",
        component: quotation,
        meta: {
          title: "Quotation"
        }
      }
    ]
  },
  {
    path: "/app-quoats",
    component: BodyView,
    children: [
      {
        path: "list",
        name: "App Quoats",
        component: appQuoats,
        meta: {
          title: "App Quoats"
        }
      }
    ]
  },
  {
    path: "/assign-marketing-work",
    component: BodyView,
    children: [
      {
        path: "list",
        name: "Assign Marketing Work",
        component: assignMarketingWork,
        meta: {
          title: "Assign Marketing Work"
        }
      }
    ]
  },
  {
    path: "/assign-work-approval",
    component: BodyView,
    children: [
      {
        path: "list",
        name: "Assign Work Approval",
        component: assignWorkApproval,
        meta: {
          title: "Assign Work Approval"
        }
      }
    ]
  },
  {
    path: "/site-creation",
    component: BodyView,
    children: [
      {
        path: "list",
        name: "Site List",
        component: siteList,
        meta: {
          title: "Site List",
          permission: "view_site_creation"
        }
      },
      {
        path: "create",
        name: "Create Site",
        component: siteCreation,
        meta: {
          title: "Create Site"
        }
      },
      {
        path: "add-site-manager/:siteId",
        name: "Add Site Manager",
        component: addSiteManager,
        meta: { title: "Add Site Manager" }
      },
      {
        path: "add-employee/:siteId",
        name: "Add Employee",
        component: addEmployee,
        meta: { title: "Add Employee" }
      },
      {
        path: "add-register/:siteId",
        name: "Add Register",
        component: addRegister,
        meta: { title: "Add Register" }
      },
      {
        path: "add-machine/:siteId",
        name: "Add Machine",
        component: addMachine,
        meta: { title: "Add Machine" }
      }
    ]
  },
  {
    path: "/new-sites",
    component: BodyView,
    children: [
      {
        path: "list",
        name: "New Sites",
        component: newSites,
        meta: { title: "New Sites" }

      }
    ]
  },
  {
    path: "/joining-finalization",
    component: BodyView,
    children: [
      {
        path: "list",
        name: "Joining Finalization",
        component: joiningFinalization,
        meta: {
          title: "Joining Finalization",
          permission: "view_joining_finalization"
        }
      }
    ]
  },
  {
    path: "/interview-questions",
    component: BodyView,
    children: [
      {
        path: "list",
        name: "Interview Questions",
        component: interviewQuestions,
        meta: {
          title: "Interview Questions",
          permission: "view_interview_questions"
        }
      }
    ]
  },
  {
    path: "/products",
    component: BodyView,
    children: [
      {
        path: "list",
        name: "Products",
        component: products,
        meta: {
          title: "Products",
          permission: "view_products"
        }
      }
    ]
  },
  {
    path: "/deduction-sheet",
    component: BodyView,
    children: [
      {
        path: "list",
        name: "Deduction Sheet",
        component: deductionSheet,
        meta: {
          title: "Deduction Sheet",
          permission: "view_deduction_sheet"
        }
      }
    ]
  },
  {
    path: "/chase-list",
    component: BodyView,
    children: [
      {
        path: "list",
        name: "Chase List",
        component: chaseList,
        meta: {
          title: "Chase List",
          permission: "view_chase_list"
        }
      }
    ]
  },
  {
    path: "/incentives",
    component: BodyView,
    children: [
      {
        path: "list",
        name: "Incentives",
        component: incentives,
        meta: {
          title: "Incentives",
          permission: "view_incentives"
        }
      }
    ]
  },
  {
    path: "/employee-list",
    component: BodyView,
    children: [
      {
        path: "all-employee-list",
        name: "All Employee List",
        component: allEmployeeList,
        meta: {
          title: "All Employee List"
        }
      },
      {
        path: "black-listed-employee",
        name: "Black Listed Employee",
        component: blackListedEmployee,
        meta: {
          title: "Black Listed Employee"
        }
      },
      {
        path: "employee-job-life/:id",
        name: "Employee Job Life",
        component: employeeJobLife,
        meta: {
          title: "Employee Job Life"
        }
      }
    ]
  },
  {
    path: "/stocks",
    component: BodyView,
    children: [
      {
        path: "out-of-stocks",
        name: "Out of Stocks",
        component: outOfStocks,
        meta: {
          title: "Out of Stocks"
        }
      }
    ]
  },
  {
    path: "/docs",
    component: BodyView,
    meta: {
      permission: "view_docs"
    },
    children: [
      {
        path: "list",
        name: "Docs",
        component: docs,
        meta: {
          title: "Docs",
          permission: "view_docs"
        }
      },
      {
        path: "return",
        name: "Return Docs",
        component: returnDocs,
        meta: {
          title: "Return Docs",
          permission: "view_return_docs"
        }
      }
    ]
  },
  {
    path: "/documents",
    component: BodyView,
    children: [
      {
        path: "return-documents",
        name: "Return Documents",
        component: returnDocument,
        meta: {
          title: "Return Documents"
        }
      }
    ]
  },
  {
    path: "/pf-esic-details",
    component: BodyView,
    children: [
      {
        path: "list",
        name: "Pf Esic Details",
        component: pfEsicDetails,
        meta: {
          title: "PF/ESIC Details"
        }
      },
      {
        path: "site-employees/:id",
        name: "Site Employees",
        component: employeeDetails,
        meta: {
          title: "Site Employees"
        }
      }
    ]
  },
  {
    path: "/pv-details",
    component: BodyView,
    children: [
      {
        path: "list",
        name: "PV Details",
        component: pvDetails,
        meta: {
          title: "PV Details",
          permission: "view_pv_details"
        }
      },
    ]
  },
  {
    path: "/variation-chart",
    component: BodyView,
    children: [
      {
        path: "list",
        name: "Variation Chart",
        component: variationChart,
        meta: {
          title: "Variation Chart",
          permission: "view_variation_chart"
        }
      },
    ]
  },
  {
    path: "/materials",
    component: BodyView,
    meta: {
      permission: "view_materials"
    },
    children: [
      {
        path: "orders",
        name: "Orders",
        component: orders,
        meta: {
          title: "Orders",
          permission: "view_orders"
        }
      },
      {
        path: "goods",
        name: "Goods",
        component: goods,
        meta: {
          title: "Goods",
          permission: "view_goods"
        }
      }
    ]
  },
  {
    path: "/settings",
    component: BodyView,
    meta: {
      permission: "view_settings"
    },
    children: [
      {
        path: "services",
        name: "Services",
        component: services,
        meta: {
          title: "Services",
          permission: "view_services_settings"
        }
      },
      {
        path: "designation",
        name: "Designation",
        component: designation,
        meta: {
          title: "Designation",
          permission: "view_settings"
        }
      },
      {
        path: "interview-questions",
        name: "Interview Questions",
        component: interviewQuestions,
        meta: {
          title: "Interview Questions",
          permission: "view_interview_questions"
        }
      },
      {
        path: "department",
        name: "Department",
        component: department,
        meta: {
          title: "Department",
          permission: "view_department"
        }
      },
      {
        path: "general-settings",
        name: "General Settings",
        component: generalSettings,
        meta: {
          title: "General Settings",
          permission: "view_general_settings"
        }
      },
      {
        path: "areas",
        name: "Areas",
        component: areas,
        meta: {
          title: "Areas",
          permission: "view_area_management"
        }
      },
      {
        path: "competitor",
        name: "Competitor",
        component: competitor,
        meta: {
          title: "Competitor",
        }
      }
    ]
  },
  {
    path: "/joining",
    component: BodyView,
    meta: {
      permission: "view_joining_settings"
    },
    children: [
      {
        path: "assigning-panel",
        name: "Assigning Panel",
        component: assigningPanel,
        meta: {
          title: "Assigning Panel",
          permission: "view_assigning_panel"
        }
      },
      {
        path: "site-joining",
        name: "Site Joining",
        component: siteJoining,
        meta: {
          title: "Site Joining",
          permission: "view_site_joining"
        }
      }
    ]
  },
  {
    path: "/403",
    component: error403,
    meta: {
      title: "403"
    }
  },
  {
    path: "/marketing-kyt-approval",
    component: BodyView,
    meta: {
      permission: "view_joining_settings"
    },
    children: [
      {
        path: "list",
        name: "Marketing KYT Approval",
        component: marketingKYTApproval,
        meta: {
          title: "Marketing KYT Approval",
          permission: "view_joining_settings"
        }
      }
    ]
  },
  {
    path: '/quotation/new',
    name: 'NewQuotation',
    component: () => import('@/pages/quotation/new.vue'),
    meta: {
      requiresAuth: true,
      title: 'New Quotation'
    }
  },
  {
    path: '/reports/operation-reports/material-request-report',
    name: 'MaterialRequestReport',
    component: () => import('../pages/reports/operation-reports/material-request-report.vue')
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkExactActiveClass: 'active',
})
router.afterEach((to) => {
  nextTick(() => {
    document.title = to.meta.title ? to.meta.title as string : 'Default Title';
  });
});
router.beforeEach((to, from, next) => {
  const token = localStorage.getItem('token');

  // Public route handling
  if (to.path === '/auth/login') {
    if (token) {
      next('/');
      return;
    }
    next();
    return;
  }

  // Authentication check
  if (!token) {
    next('/auth/login');
    return;
  }

  // Permission check
  const requiredPermission = to.meta?.permission as string;
  if (requiredPermission && !hasPermission(requiredPermission)) {
    // Redirect to 403 page or dashboard
    next('/403');
    return;
  }

  next();
});

export default router
