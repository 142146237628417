<template>
    <div class="content-wrapper">
        <!-- Toast Container -->
        <div class="toast-container position-fixed top-0 end-0 p-3">
            <div v-if="notification.show" class="toast show" role="alert" aria-live="assertive" aria-atomic="true">
                <div :class="['toast-header', notification.type]">
                    <strong class="me-auto">{{ notification.type === 'bg-success text-white' ? 'Success' : 'Error'
                        }}</strong>
                    <button type="button" class="btn-close" @click="notification.show = false"></button>
                </div>
                <div class="toast-body">
                    {{ notification.message }}
                </div>
            </div>
        </div>

        <div class="content-header">
            <h1></h1>
            <button class="btn btn-primary" @click="openAddModal">
                Add Competitor
            </button>
        </div>

        <div class="content">
            <div class="card">
                <div class="card-body">
                    <!-- Add search input -->
                    <div class="mb-3 d-flex justify-content-end">
                        <div class="col-md-4">
                            <input type="text" class="form-control" placeholder="Search competitor..."
                                v-model="searchQuery" @input="handleSearch">
                        </div>
                    </div>
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th class="text-center">S.No</th>
                                <th class="text-center"> Name</th>
                                <th class="text-center">Created At</th>
                                <th class="text-end">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(competitor, index) in competitors" :key="competitor._id">
                                <td class="text-center " style="width: 50px">{{ competitor.sl }}</td>
                                <td class="text-center">{{ competitor.name }}</td>
                                <td class="text-center">{{ formatDate(competitor.createdAt) }}</td>
                                <td class="text-end">
                                    <i class="fas fa-edit text-primary me-3 cursor-pointer"
                                        @click="openEditModal(competitor)"></i>
                                    <i class="fas fa-trash-alt text-danger cursor-pointer"
                                        @click="confirmDelete(competitor)"></i>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <!-- Pagination -->
                    <div v-if="pagination" class="d-flex justify-content-between align-items-center mt-3">
                        <div>
                            Showing {{ (currentPage - 1) * itemsPerPage + 1 }} to
                            {{ Math.min(currentPage * itemsPerPage, pagination.totalItems) }} of
                            {{ pagination.totalItems }} entries
                        </div>
                        <nav>
                            <ul class="pagination mb-0">
                                <li class="page-item" :class="{ disabled: currentPage === 1 }">
                                    <a class="page-link" href="#"
                                        @click.prevent="changePage(currentPage - 1)">Previous</a>
                                </li>
                                <li v-for="page in pagination.totalPages" :key="page" class="page-item"
                                    :class="{ active: page === currentPage }">
                                    <a class="page-link" href="#" @click.prevent="changePage(page)">{{ page }}</a>
                                </li>
                                <li class="page-item" :class="{ disabled: currentPage === pagination.totalPages }">
                                    <a class="page-link" href="#" @click.prevent="changePage(currentPage + 1)">Next</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div class="modal fade" id="addCompetitorModal" tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">{{ isEditing ? 'Edit Competitor' : 'Add Competitor' }}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                    </div>
                    <div class="modal-body">
                        <div class="form-group mb-3">
                            <label>Competitor Name</label>

                            <input type="text" class="form-control" v-model="competitorForm.name" required>
                        </div>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" class="btn btn-primary" @click="saveCompetitor">Save</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Delete Confirmation Modal -->
        <div class="modal fade" id="deleteConfirmModal" tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">Confirm Delete</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
                    </div>
                    <div class="modal-body">
                        Are you sure you want to delete the competitor "{{ selectedCompetitor?.name }}"?
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
                        <button type="button" class="btn btn-danger" @click="deleteCompetitor">Delete</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import { fetchGetApi, fetchPostApi } from "@/util/api";
import { Modal } from 'bootstrap';

const competitors = ref([]);
const competitorForm = ref({
    name: '',
    _id: null
});
const isEditing = ref(false);
const currentPage = ref(1);
const itemsPerPage = ref(20);
const pagination = ref(null);
let modal = null;
const selectedCompetitor = ref(null);
const deleteModal = ref(null);
const searchQuery = ref('');
const searchTimeout = ref(null);

const notification = ref({
    show: false,
    message: '',
    type: 'bg-success text-white'
});

const showNotification = (message, type = 'bg-success text-white') => {
    notification.value = {
        show: true,
        message,
        type
    };
    // Auto hide after 3 seconds
    setTimeout(() => {
        notification.value.show = false;
    }, 3000);
};

onMounted(async () => {
    await loadCompetitors();
    modal = new Modal(document.getElementById('addCompetitorModal'));
    deleteModal.value = new Modal(document.getElementById('deleteConfirmModal'));
});

const handleSearch = () => {
    // Clear existing timeout
    if (searchTimeout.value) {
        clearTimeout(searchTimeout.value);
    }

    // Set new timeout for debouncing
    searchTimeout.value = setTimeout(() => {
        currentPage.value = 1; // Reset to first page when searching
        loadCompetitors();
    }, 500); // Wait for 500ms after user stops typing
};

const loadCompetitors = async () => {
    try {
        const searchParam = searchQuery.value ? `&search=${searchQuery.value}` : '';
        const response = await fetchGetApi(`api/competitor/get/all?page=${currentPage.value}${searchParam}`);
        if (response && response.data) {
            competitors.value = response.data.competitors;
            pagination.value = {
                currentPage: response.data.currentPage,
                totalPages: response.data.totalPages,
                totalItems: response.data.totalItems,
                itemsPerPage: 10
            };
        }
    } catch (error) {
        console.error('Error loading competitors:', error);
        competitors.value = [];
        pagination.value = {
            currentPage: 1,
            totalPages: 1,
            totalItems: 0,
            itemsPerPage: 10
        };
    }
};

const changePage = async (page) => {
    if (page >= 1 && page <= pagination.value.totalPages) {
        currentPage.value = page;
        await loadCompetitors();
    }
};

const openAddModal = () => {
    isEditing.value = false;
    competitorForm.value = {
        name: '',
        _id: null
    };
    modal.show();
};

const openEditModal = (competitor) => {
    isEditing.value = true;
    competitorForm.value = { ...competitor };
    modal.show();
};

const saveCompetitor = async () => {
    try {
        if (isEditing.value) {
            await fetchPostApi(`api/competitor/update/${competitorForm.value._id}`, competitorForm.value);
            showNotification('Competitor updated successfully!', 'bg-success text-white');
        } else {
            await fetchPostApi('api/competitor/create', competitorForm.value);
            showNotification('Competitor added successfully!', 'bg-success text-white');
        }
        await loadCompetitors();
        modal.hide();
    } catch (error) {
        console.error('Error saving competitor:', error);
        showNotification('Failed to save competitor. Please try again.', 'bg-danger text-white');
    }
};

const confirmDelete = (competitor) => {
    selectedCompetitor.value = competitor;
    deleteModal.value.show();
};

const deleteCompetitor = async () => {
    try {
        await fetchPostApi(`api/competitor/delete/${selectedCompetitor.value._id}`);
        await loadCompetitors();
        deleteModal.value.hide();
        selectedCompetitor.value = null;
        showNotification('Competitor deleted successfully!', 'bg-success text-white');
    } catch (error) {
        console.error('Error deleting competitor:', error);
        showNotification('Failed to delete competitor. Please try again.', 'bg-danger text-white');
    }
};

function formatDate(date) {
    const d = new Date(date);
    const day = String(d.getDate()).padStart(2, '0');
    const month = String(d.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = d.getFullYear();
    return `${day}-${month}-${year}`;
}
</script>

<style scoped>
.content-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1rem;
}

.card {
    margin-bottom: 1rem;
}

.cursor-pointer {
    cursor: pointer;
}

.fas {
    font-size: 1.1rem;
}

.toast-container {
    z-index: 9999;
}

.toast {
    min-width: 300px;
}

.bg-success.text-white {
    background-color: #28a745 !important;
    color: white !important;
}

.bg-danger.text-white {
    background-color: #dc3545 !important;
    color: white !important;
}
</style>