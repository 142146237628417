<script lang="js" setup>
import { ref, onMounted, computed, watch } from "vue";
import { fetchGetApi } from "@/util/api";
import VueSlider from 'vue-slider-component'
import { useRouter } from 'vue-router';
import { jsonToExcel } from "@/util/makeExcel";

const router = useRouter();
const allData = ref([]);
import Swal from 'sweetalert2';
import { useToast } from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-default.css';
import { defineAsyncComponent } from 'vue';
import { Modal } from 'bootstrap';
import { is } from "date-fns/locale";

const modalCode = defineAsyncComponent(() => import("@/components/roles/modalCode.vue"));
const elementsPerPage = ref(25);
const currentPage = ref(1);
const filterQuery = ref("");
const toast = useToast();
const minPercentage = ref(0);
const maxPercentage = ref(100);
const selectedQuote = ref(null);
let quoteModal = null;
const selectedCriteria = ref(null);
let criteriaModal = null;
const sortOrder = ref('new'); // 'new' or 'old'
const selectedReports = ref(null);
let reportsModal = null;
const startDate = ref(null);
const endDate = ref(null);
const showImageModal = ref(false);
const selectedImage = ref('');
const selectedImageTitle = ref('');

const columnFilters = ref({
    projectName: '',
    managerName: '',
    area: '',
    servicesAndManpower: '',
    criteria: ''
});

const percentageRange = computed({
    get() {
        return [minPercentage.value, maxPercentage.value]
    },
    set([min, max]) {
        minPercentage.value = min
        maxPercentage.value = max
    }
});

const filteredDataBeforePagination = computed(() => {
    const data = Array.isArray(allData.value) ? [...allData.value] : [];

    const filtered = data.filter(item => {
        const matchesPercentage = item.rating >= minPercentage.value && item.rating <= maxPercentage.value;

        // Add date filtering
        let matchesDate = true;
        if (startDate.value || endDate.value) {
            const [itemDate] = item.lastReportDate.split(',');
            const [day, month, year] = itemDate.trim().split('-');
            const reportDate = new Date(year, month - 1, day); // month is 0-based in JS Date

            if (startDate.value) {
                const start = new Date(startDate.value);
                start.setHours(0, 0, 0, 0);
                matchesDate = matchesDate && reportDate >= start;
            }
            if (endDate.value) {
                const end = new Date(endDate.value);
                end.setHours(23, 59, 59, 999);
                matchesDate = matchesDate && reportDate <= end;
            }
        }

        const matchesProjectName = item.projectName?.toLowerCase().includes(columnFilters.value.projectName.toLowerCase());
        const matchesManager = item.managerName?.toLowerCase().includes(columnFilters.value.managerName.toLowerCase());
        const matchesArea = item.area?.toLowerCase().includes(columnFilters.value.area.toLowerCase());
        const matchesServices = item.servicesAndManpower?.toLowerCase().includes(columnFilters.value.servicesAndManpower.toLowerCase());

        const matchesCriteria = columnFilters.value.criteria === '' ||
            item.criteria?.some(criterion =>
                criterion.service?.toLowerCase().includes(columnFilters.value.criteria.toLowerCase()) ||
                criterion.criteria?.toLowerCase().includes(columnFilters.value.criteria.toLowerCase())
            );

        return matchesPercentage && matchesProjectName && matchesManager &&
            matchesArea && matchesServices && matchesCriteria && matchesDate;
    });

    filtered.sort((a, b) => {
        try {
            const [dateA, timeA] = a.lastReportDate.split(', ');
            const [dayA, monthA, yearA] = dateA.split('-');
            const dateObjA = new Date(`${yearA}-${monthA}-${dayA} ${timeA}`);

            const [dateB, timeB] = b.lastReportDate.split(', ');
            const [dayB, monthB, yearB] = dateB.split('-');
            const dateObjB = new Date(`${yearB}-${monthB}-${dayB} ${timeB}`);

            return sortOrder.value === 'new' ? dateObjB - dateObjA : dateObjA - dateObjB;
        } catch (error) {
            console.error('Error sorting dates:', error);
            return 0;
        }
    });

    return filtered;
});

const filteredData = computed(() => {
    const start = (currentPage.value - 1) * elementsPerPage.value;
    const end = start + elementsPerPage.value;
    return filteredDataBeforePagination.value.slice(start, end);
});

function getData() {
    fetchGetApi('api/percentage/report').then((response) => {
        if (response && response.data && response.data.length > 0) {
            allData.value = response.data;
            if (Array.isArray(allData.value)) {
                allData.value.forEach(project => {
                    if (project.siteId) {
                        getVisitDuration(project.siteId);
                    }
                });
            }
        } else {
            toast.error('Failed to fetch data')
        }
    });
}

function getStatusClass(status) {
    switch (status) {
        case 'Excellent':
            return 'text-success';
        case 'Average':
            return 'text-warning';
        case 'Needs Improvement':
            return 'text-danger';
        default:
            return 'text-secondary';
    }
}

function num_pages() {
    return Math.ceil(filteredDataBeforePagination.value.length / elementsPerPage.value);
}

function change_page(page) {
    currentPage.value = page;
}

function previousPage() {
    if (currentPage.value > 1) {
        currentPage.value--;
    }
}

function nextPage() {
    if (currentPage.value < num_pages()) {
        currentPage.value++;
    }
}

function viewDetails(item) {
    window.open(`/reports/project/${item.areaId}/${item.siteId}`, '_blank');
}

function showQuoteDetails(quote) {
    selectedQuote.value = quote;
    quoteModal.show();
}

function showCriteriaDetails(criteria) {
    selectedCriteria.value = criteria;
    criteriaModal.show();
}

function validatePercentage(type) {
    if (type === 'min') {
        // Ensure min is not less than 0
        if (minPercentage.value < 0) minPercentage.value = 0;
        // Ensure min is not greater than max
        if (minPercentage.value > maxPercentage.value) minPercentage.value = maxPercentage.value;
        // Ensure min is not greater than 100
        if (minPercentage.value > 100) minPercentage.value = 100;
    } else {
        // Ensure max is not greater than 100
        if (maxPercentage.value > 100) maxPercentage.value = 100;
        // Ensure max is not less than min
        if (maxPercentage.value < minPercentage.value) maxPercentage.value = minPercentage.value;
        // Ensure max is not less than 0
        if (maxPercentage.value < 0) maxPercentage.value = 0;
    }
}

function showReports(reports) {
    selectedReports.value = reports;
    reportsModal.show();
}

function formatDate(dateString) {
    const [date, time] = dateString.split(', ');
    const [day, month, year] = date.split('-');

    // Convert time to 12-hour format
    let [hour, minute] = time.split(':');
    hour = parseInt(hour, 10);
    const isPM = hour >= 12;
    const formattedHour = hour % 12 || 12; // Convert to 12-hour format
    const period = isPM ? 'PM' : 'AM';

    return {
        date: `${year}-${month}-${day}`,
        time: `${formattedHour}:${minute} ${period}`
    };
}

function formatReportText(reports) {
    if (!Array.isArray(reports) || reports.length === 0) return '';

    const maxLength = 150; // Maximum characters before showing "View More"
    let displayText = '';
    let totalLength = 0;

    for (let i = 0; i < reports.length; i++) {
        const report = reports[i]?.trim() || '';
        if (totalLength + report.length <= maxLength) {
            displayText += (displayText ? ', ' : '') + report;
            totalLength += report.length;
        } else {
            return {
                text: displayText,
                hasMore: true
            };
        }
    }

    return {
        text: displayText,
        hasMore: false
    };
}

function downloadExcel() {
    const dataToExport = filteredDataBeforePagination.value.map((item, index) => {
        // Get criteria details
        const criteriaDetails = (item.criteria || []).map(criterion => ({
            service: criterion.service || '',
            criteria: criterion.criteria || ''
        }));

        const formattedDate = formatDate(item.lastReportDate);

        return {
            'SL': ((currentPage.value - 1) * elementsPerPage.value) + index + 1,
            'Project Name': item.projectName || '',
            'Rating': `${item.rating || 0}%`,
            'Manager Name': item.managerName || '',
            'Last Report Date': formattedDate.date,
            'Last Report Time': formattedDate.time,
            'Area': item.area || '',
            'Services And Manpower': item.servicesAndManpower || '',
            'Total Manpower': item.totalManpower || '',
            'Criteria': criteriaDetails.map(c => `${c.service}: ${c.criteria}`).join('\n'),
            'Quote Submitted': item.quoteSubmitted ? 'Yes' : 'No',
            'Quote Details': item.quoteId ? 'Available' : 'Not Available'
        };
    });

    // Add a timestamp to the filename
    const timestamp = new Date().toISOString().split('T')[0];
    jsonToExcel(dataToExport, `percentage-analysis-report-${timestamp}.xlsx`);
    toast.success('Excel file downloaded successfully');
}

onMounted(async () => {
    getData();
    quoteModal = new Modal(document.getElementById('quoteDetailsModal'));
    criteriaModal = new Modal(document.getElementById('criteriaDetailsModal'));
    reportsModal = new Modal(document.getElementById('reportsModal'));
});

// Add this watch to handle page changes when filters change
watch(filteredDataBeforePagination, () => {
    const maxPage = num_pages();
    if (currentPage.value > maxPage) {
        currentPage.value = maxPage || 1;
    }
});

// Add a watch for date range to reset to first page when dates change
watch([startDate, endDate], () => {
    currentPage.value = 1;
});

function getVisitDuration(SiteID) {
    const url = `api/sites/visit-duration/${SiteID}`;
    fetchGetApi(url).then((response) => {
        console.log('Visit Duration Response:', response.data.data);
        // Find the project in allData and update its visit duration info
        const projectIndex = allData.value.findIndex(project => project.siteId === SiteID);
        if (projectIndex !== -1) {
            allData.value[projectIndex].visitDurationData = response.data.data;
        }
    }).catch(error => {
        console.error('API Error:', error);
        // toast.error('Error fetching visit duration');
    });
}

function viewImage(imageUrl, title) {
    selectedImage.value = imageUrl;
    selectedImageTitle.value = title;
    showImageModal.value = true;
}

function calculateDuration(entryTime, exitTime) {
    if (!entryTime || !exitTime) return 'N/A';

    try {
        // Convert times to Date objects
        const [entryHours, entryMinutes] = entryTime.split(':').map(Number);
        const [exitHours, exitMinutes] = exitTime.split(':').map(Number);

        // Calculate total minutes for each time
        const entryTotalMinutes = entryHours * 60 + entryMinutes;
        const exitTotalMinutes = exitHours * 60 + exitMinutes;

        // Calculate duration in minutes
        let durationMinutes = exitTotalMinutes - entryTotalMinutes;

        // Handle case where exit time is on the next day
        if (durationMinutes < 0) {
            durationMinutes += 24 * 60; // Add 24 hours worth of minutes
        }

        // Format the duration
        const hours = Math.floor(durationMinutes / 60);
        const minutes = durationMinutes % 60;

        if (hours > 0) {
            return `${hours} hr${hours > 1 ? 's' : ''} ${minutes} min${minutes > 1 ? 's' : ''}`;
        } else {
            return `${minutes} min${minutes > 1 ? 's' : ''}`;
        }
    } catch (error) {
        console.error('Error calculating duration:', error);
        return 'N/A';
    }
}

function formatTime(time) {
    if (!time) return 'N/A';
    try {
        const [hours, minutes] = time.split(':').map(Number);
        const period = hours >= 12 ? 'PM' : 'AM';
        const formattedHours = hours % 12 || 12;
        return `${formattedHours}:${minutes.toString().padStart(2, '0')} ${period}`;
    } catch (error) {
        console.error('Error formatting time:', error);
        return time;
    }
}

</script>
<template>
    <Breadcrumbs main="Apps" title="Percentage analysis" />
    <div class="container-fluid card ">
        <div class="email-wrap bookmark-wrap">
            <div class="row ">
                <div class="table-responsive product-table custom-scrollbar card-body">
                    <form class="no-footer" @submit.prevent>
                        <div class="mb-3 row">
                            <div class="col-xs-12 col-sm-9">
                                <div class="range-selector-container">
                                    <div class="d-flex align-items-start gap-4">
                                        <div class="slider-container flex-grow-1">
                                            <div class="d-flex justify-content-between align-items-center mb-2">
                                                <label class="form-label fw-bold mb-0">Percentage Range</label>
                                                <div class="percentage-badge">
                                                    {{ minPercentage }}% - {{ maxPercentage }}%
                                                </div>
                                            </div>
                                            <vue-slider v-model="percentageRange" :min="0" :max="100" :interval="1"
                                                :process="true" :tooltip="'always'" :contained="true" :dot-style="{
                                                    border: '2px solid #7366ff',
                                                    background: '#fff',
                                                    boxShadow: '0 2px 4px rgba(115, 102, 255, 0.2)'
                                                }" :process-style="{
                                                    backgroundColor: '#7366ff'
                                                }" :rail-style="{
                                                    backgroundColor: '#e0e0e0'
                                                }" :tooltip-style="{
                                                    backgroundColor: '#7366ff',
                                                    borderColor: '#7366ff',
                                                    color: '#fff'
                                                }" />
                                        </div>
                                        <div class="percentage-inputs d-flex flex-column justify-content-center gap-2">
                                            <label class="form-label small mb-1">Manual Input</label>
                                            <div class="input-group">
                                                <input type="number" class="form-control form-control-sm text-center"
                                                    v-model.number="minPercentage" min="0" max="100"
                                                    @input="validatePercentage('min')">
                                                <span class="input-group-text">-</span>
                                                <input type="number" class="form-control form-control-sm text-center"
                                                    v-model.number="maxPercentage" min="0" max="100"
                                                    @input="validatePercentage('max')">
                                                <span class="input-group-text">%</span>
                                            </div>
                                        </div>
                                        <div class="date-range-inputs d-flex flex-column justify-content-center gap-2">
                                            <label class="form-label fw-bold mb-1">Date Range</label>
                                            <div class="d-flex align-items-center gap-2">
                                                <input type="date" class="form-control form-control-sm"
                                                    v-model="startDate" placeholder="Start Date">
                                                <span>-</span>
                                                <input type="date" class="form-control form-control-sm"
                                                    v-model="endDate" placeholder="End Date">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="mt-3">
                                    <label class="form-label fw-bold me-2">Sort by Date:</label>
                                    <select v-model="sortOrder"
                                        class="form-select form-select-sm d-inline-block w-auto">
                                        <option value="new">Newest First</option>
                                        <option value="old">Oldest First</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-xs-12 col-sm-3 text-end d-flex align-items-start justify-content-end">
                                <button type="button" class="btn btn-primary" @click="downloadExcel">
                                    <i class="fas fa-download me-1"></i>
                                    Download Excel
                                </button>
                            </div>
                        </div>
                        <table class="display table-striped table-hover table-bordered table " id="basic-1">
                            <thead>
                                <tr>
                                    <th class="sorting_asc" scope="col" style="width: 50px;">SL</th>
                                    <th class="sorting_asc text-nowrap" scope="col">
                                        Project Name
                                        <input type="text" class="form-control form-control-sm mt-1"
                                            v-model="columnFilters.projectName" placeholder="Filter...">
                                    </th>
                                    <th class="sorting_asc text-nowrap" scope="col">Percentage</th>
                                    <th class="sorting_asc text-nowrap" scope="col">
                                        Manager Name
                                        <input type="text" class="form-control form-control-sm mt-1"
                                            v-model="columnFilters.managerName" placeholder="Filter...">
                                    </th>
                                    <th class="sorting_asc text-nowrap" scope="col">Last date of <br> report</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Visit Duration</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Last report</th>
                                    <th class="sorting_asc text-nowrap" scope="col">
                                        Area
                                        <input type="text" class="form-control form-control-sm mt-1"
                                            v-model="columnFilters.area" placeholder="Filter...">
                                    </th>
                                    <th class="sorting_asc text-nowrap" scope="col">
                                        Service targeted<br>
                                        and manpower
                                        <input type="text" class="form-control form-control-sm mt-1"
                                            v-model="columnFilters.servicesAndManpower" placeholder="Filter...">
                                    </th>
                                    <th class="sorting_asc text-nowrap" scope="col">Total<br>Manpower</th>
                                    <th class="sorting_asc text-nowrap" scope="col">
                                        Criterias
                                        <input type="text" class="form-control form-control-sm mt-1"
                                            v-model="columnFilters.criteria" placeholder="Filter...">
                                    </th>
                                    <th class="sorting_asc" scope="col">Quote/proposal submitted</th>
                                    <th class="sorting_asc text-nowrap" scope="col">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="item in filteredData" :key="item.slNo">
                                    <td>{{ item.slNo }}</td>
                                    <td>{{ item.projectName }}</td>
                                    <td>
                                        <span :class="getStatusClass(item.status)">
                                            {{ item.rating }}%
                                        </span>
                                    </td>
                                    <td>{{ item.managerName }}</td>
                                    <td>
                                        <div class="date-display">
                                            <div class="date">{{ formatDate(item.lastReportDate).date }}</div>
                                            <div class="time">{{ formatDate(item.lastReportDate).time }}</div>
                                        </div>
                                    </td>

                                    <td>
                                        <template v-if="item.visitDurationData && item.visitDurationData.length > 0">
                                            <div class="visit-duration-info">
                                                <div class="entry-info">
                                                    <span class="duration-label">Entry:</span>
                                                    <span class="duration-time">{{
                                                        formatTime(item.visitDurationData[0].entryTime) }}</span>
                                                    <i class="fa fa-eye cursor-pointer ms-2 eye-icon"
                                                        @click="viewImage(item.visitDurationData[0].inPhoto, 'Entry Photo')"
                                                        v-if="item.visitDurationData[0].inPhoto"></i>
                                                </div>
                                                <div class="exit-info">
                                                    <span class="duration-label">Exit:</span>
                                                    <span class="duration-time">{{
                                                        formatTime(item.visitDurationData[0].exitTime) }}</span>
                                                    <i class="fa fa-eye cursor-pointer ms-2 eye-icon"
                                                        @click="viewImage(item.visitDurationData[0].outPhoto, 'Exit Photo')"
                                                        v-if="item.visitDurationData[0].outPhoto"></i>
                                                </div>
                                                <div class="total-duration">
                                                    <span class="duration-label">Duration:</span>
                                                    <span class="duration-value">{{
                                                        calculateDuration(item.visitDurationData[0].entryTime,
                                                            item.visitDurationData[0].exitTime) }}</span>
                                                </div>
                                            </div>
                                        </template>
                                        <template v-else>
                                            N/A
                                        </template>
                                    </td>
                                    <td>
                                        <template v-if="item.lastReport && item.lastReport.length > 0">
                                            <div class="last-report-content">
                                                {{ formatReportText(item.lastReport).text }}
                                                <button v-if="formatReportText(item.lastReport).hasMore"
                                                    class="btn btn-link btn-xs text-primary p-0 ms-1"
                                                    @click.prevent="showReports(item.lastReport)"
                                                    title="View All Reports">
                                                    (View More)
                                                </button>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <span class="text-muted">No reports available</span>
                                        </template>
                                    </td>
                                    <td>{{ item.area }}</td>
                                    <td>
                                        <template v-for="(service, index) in item.servicesAndManpower.split('\n')"
                                            :key="index">
                                            {{ service }}
                                            <br v-if="index < item.servicesAndManpower.split('\n').length - 1">
                                        </template>
                                    </td>
                                    <td>{{ item.totalManpower }}</td>
                                    <td>
                                        <button class="btn btn-info btn-xs ms-2"
                                            @click.prevent="showCriteriaDetails(item.criteria)"
                                            title="View Criteria Details">
                                            <i class="fa fa-list-alt"></i>
                                        </button>
                                    </td>
                                    <td>
                                        <span :class="item.quoteSubmitted ? 'text-success' : 'text-danger'">
                                            {{ item.quoteSubmitted ? 'Yes' : 'No' }}
                                        </span>
                                        <button v-if="item.quoteId" class="btn btn-info btn-xs ms-2"
                                            @click.prevent="showQuoteDetails(item.quoteId)" title="View Quote Details">
                                            <i class="fa fa-file-text-o"></i>
                                        </button>
                                    </td>
                                    <td>
                                        <button class="btn btn-primary btn-xs" @click="viewDetails(item)"
                                            title="View Details">
                                            <i class="fa fa-eye"></i>
                                        </button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>

                <ul class="pagination py-2 justify-content-end pagination-primary">
                    <li class="page-item" @click="previousPage">
                        <a class="page-link">Previous</a>
                    </li>
                    <li class="page-item" v-for="i in num_pages()" :key="i" :class="[i == currentPage ? 'active' : '']"
                        @click="change_page(i)">
                        <a class="page-link">{{ i }}</a>
                    </li>
                    <li class="page-item" @click="nextPage">
                        <a class="page-link">Next</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>

    <div class="modal fade" id="quoteDetailsModal" tabindex="-1" aria-labelledby="quoteDetailsModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="quoteDetailsModalLabel">Quote Details</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div v-if="selectedQuote">
                        <div class="mb-4">
                            <h6 class="fw-bold">Project Details</h6>
                            <div class="table-responsive">
                                <table class="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <th width="200">Project Name</th>
                                            <td>{{ selectedQuote.projectName }}</td>
                                        </tr>
                                        <tr>
                                            <th>Status</th>
                                            <td>{{ selectedQuote.status }}</td>
                                        </tr>
                                        <tr>
                                            <th>Total Before GST</th>
                                            <td>₹{{ selectedQuote.totalBeforeGST.toLocaleString() }}</td>
                                        </tr>
                                        <tr>
                                            <th>GST Amount</th>
                                            <td>₹{{ selectedQuote.totalGSTAmount.toLocaleString() }}</td>
                                        </tr>
                                        <tr>
                                            <th>Total Amount</th>
                                            <td>₹{{ selectedQuote.totalAmount.toLocaleString() }}</td>
                                        </tr>
                                        <tr>
                                            <th>Created At</th>
                                            <td>{{ new Date(selectedQuote.createdAt).toLocaleString() }}</td>
                                        </tr>
                                        <tr>
                                            <th>Updated At</th>
                                            <td>{{ new Date(selectedQuote.updatedAt).toLocaleString() }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div class="mb-4" v-for="(service, index) in selectedQuote.services" :key="service._id">
                            <h6 class="fw-bold">Service {{ index + 1 }}: {{ service.serviceName }}</h6>
                            <div class="table-responsive">
                                <table class="table table-bordered">
                                    <tbody>
                                        <tr>
                                            <th width="200">Rate</th>
                                            <td>₹{{ service.rate.toLocaleString() }}</td>
                                        </tr>
                                        <tr>
                                            <th>Manpower</th>
                                            <td>{{ service.manpower }}</td>
                                        </tr>
                                        <tr>
                                            <th>Before GST</th>
                                            <td>₹{{ service.beforeGST.toLocaleString() }}</td>
                                        </tr>
                                        <tr>
                                            <th>GST</th>
                                            <td>{{ service.gst }}%</td>
                                        </tr>
                                        <tr>
                                            <th>GST Amount</th>
                                            <td>₹{{ service.gstAmount.toLocaleString() }}</td>
                                        </tr>
                                        <tr>
                                            <th>Total Amount</th>
                                            <td>₹{{ service.totalAmount.toLocaleString() }}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <div class="mt-3">
                                <h6 class="fw-bold">Breakup Details</h6>
                                <div class="table-responsive">
                                    <table class="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <th width="200">In Hand Salary</th>
                                                <td>₹{{ service.breakupDetails.inHandSalary.toLocaleString() }}</td>
                                            </tr>
                                            <tr>
                                                <th>Basic + VDA</th>
                                                <td>₹{{ service.breakupDetails.basicVDA.toLocaleString() }}</td>
                                            </tr>
                                            <tr>
                                                <th>Allowances</th>
                                                <td>₹{{ service.breakupDetails.allowancesAndConveyances.toLocaleString()
                                                    }}</td>
                                            </tr>
                                            <tr>
                                                <th>ESI</th>
                                                <td>₹{{ service.breakupDetails.esi.toLocaleString() }}</td>
                                            </tr>
                                            <tr>
                                                <th>EPF</th>
                                                <td>₹{{ service.breakupDetails.epf.toLocaleString() }}</td>
                                            </tr>
                                            <tr>
                                                <th>Reliever Charges</th>
                                                <td>₹{{ service.breakupDetails.relieverCharges.toLocaleString() }}</td>
                                            </tr>
                                            <tr>
                                                <th>Working Hours</th>
                                                <td>{{ service.breakupDetails.workingHours }}</td>
                                            </tr>
                                            <tr>
                                                <th>ESIC/EPF</th>
                                                <td>{{ service.breakupDetails.esicEpf }}</td>
                                            </tr>
                                            <tr>
                                                <th>Weekly Off</th>
                                                <td>{{ service.breakupDetails.weeklyOff }}</td>
                                            </tr>
                                            <tr>
                                                <th>Food</th>
                                                <td>{{ service.breakupDetails.food }}</td>
                                            </tr>
                                            <tr>
                                                <th>Stay</th>
                                                <td>{{ service.breakupDetails.stay }}</td>
                                            </tr>
                                            <tr>
                                                <th>Gender</th>
                                                <td>{{ service.breakupDetails.gender }}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="criteriaDetailsModal" tabindex="-1" aria-labelledby="criteriaDetailsModalLabel"
        aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="criteriaDetailsModalLabel">Criteria Details</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div v-if="selectedCriteria">
                        <div v-for="(criterion, index) in selectedCriteria" :key="index" class="criteria-item mb-4">
                            <h6 class="fw-bold">{{ criterion.service }}</h6>
                            <div class="criteria-details">
                                <pre class="criteria-text">{{ criterion.criteria }}</pre>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="reportsModal" tabindex="-1" aria-labelledby="reportsModalLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="reportsModalLabel">Last Reports</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div v-if="selectedReports">
                        <ul class="list-group">
                            <li v-for="(report, index) in selectedReports" :key="index"
                                class="list-group-item report-item" :class="{ 'text-muted': !report.trim() }">
                                {{ report.trim() || 'No content' }}
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>

    <!-- Image Modal -->
    <div class="modal-backdrop fade show" v-if="showImageModal"></div>
    <div class="modal fade" :class="{ 'show d-block': showImageModal }" tabindex="-1" v-if="showImageModal"
        @click="showImageModal = false">
        <div class="modal-dialog modal-lg" @click="handleModalClick">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">{{ selectedImageTitle }}</h5>
                    <button type="button" class="btn-close" @click="showImageModal = false"></button>
                </div>
                <div class="modal-body text-center">
                    <img :src="selectedImage" class="img-fluid" alt="Visit Photo" style="max-height: 80vh;">
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="showImageModal = false">Close</button>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
.form-control-sm {
    font-size: 0.875rem;
    padding: 0.25rem 0.5rem;
}

.badge {
    font-size: 0.75rem;
    padding: 0.25rem 0.5rem;
}

/* Enhanced slider styles */
:deep(.vue-slider) {
    padding: 1.5rem 0;
    margin: 0 1rem;
}

:deep(.vue-slider-rail) {
    background-color: #e0e0e0;
    height: 6px;
    border-radius: 3px;
}

:deep(.vue-slider-process) {
    background-color: #7366ff;
    height: 6px;
    border-radius: 3px;
}

:deep(.vue-slider-dot) {
    width: 20px;
    height: 20px;
    top: -7px;
}

:deep(.vue-slider-dot-handle) {
    border: 2px solid #7366ff;
    box-shadow: 0 2px 4px rgba(115, 102, 255, 0.2);
    background-color: white;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    transition: all 0.3s ease;
}

:deep(.vue-slider-dot-handle:hover) {
    transform: scale(1.1);
    box-shadow: 0 3px 6px rgba(115, 102, 255, 0.3);
}

:deep(.vue-slider-dot-tooltip) {
    background-color: #7366ff;
    border-color: #7366ff;
    padding: 4px 8px;
    color: white;
    font-size: 0.875rem;
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

:deep(.vue-slider-dot-tooltip-inner) {
    border-color: #7366ff;
}

/* Add a container style for better spacing */
.range-selector-container {
    background-color: #ffffff;
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
    border: 1px solid #e6e6e6;
}

.btn-xs {
    padding: 0.25rem 0.5rem;
    font-size: 0.75rem;
    line-height: 1.5;
    border-radius: 0.2rem;
}

.modal-lg {
    max-width: 800px;
}

.table th,
.table td {
    padding: 0.5rem;
    vertical-align: middle;
}

.modal-body h6 {
    color: #1e1e1e;
    margin-bottom: 1rem;
    padding-bottom: 0.5rem;
    border-bottom: 1px solid #dee2e6;
}

.modal-body .table th {
    background-color: #f8f9fa;
    font-weight: 600;
}

.modal-body .table td {
    color: #4a4a4a;
}

.criteria-item {
    background-color: #f8f9fa;
    border-radius: 8px;
    padding: 1rem;
}

.criteria-details {
    margin-top: 0.5rem;
}

.criteria-text {
    white-space: pre-wrap;
    font-family: inherit;
    font-size: 0.875rem;
    background: white;
    padding: 1rem;
    border-radius: 4px;
    border: 1px solid #dee2e6;
    margin: 0;
}

/* Rating status styles */
.text-success small {
    color: #198754;
    opacity: 0.8;
}

.text-warning small {
    color: #ffc107;
    opacity: 0.8;
}

.text-danger small {
    color: #dc3545;
    opacity: 0.8;
}

.text-secondary small {
    color: #6c757d;
    opacity: 0.8;
}

.percentage-inputs input {
    width: 70px;
    text-align: center;
}

.percentage-inputs input::-webkit-outer-spin-button,
.percentage-inputs input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.percentage-inputs input[type=number] {
    -moz-appearance: textfield;
}

.range-selector-container {
    padding: 1.5rem;
}

.slider-container {
    min-width: 200px;
}

.percentage-inputs {
    border-left: 1px solid #dee2e6;
    padding-left: 1rem;
}

.form-select-sm {
    font-size: 0.875rem;
    padding: 0.25rem 2rem 0.25rem 0.5rem;
}

.btn-link {
    text-decoration: none;
}

.btn-link:hover {
    text-decoration: underline;
}

.list-group-item {
    border-left: none;
    border-right: none;
    border-radius: 0 !important;
}

.list-group-item:first-child {
    border-top: none;
}

.list-group-item:last-child {
    border-bottom: none;
}

.report-item {
    white-space: pre-wrap;
    word-break: break-word;
    max-height: 300px;
    overflow-y: auto;
}

.modal-dialog {
    max-width: 600px;
}

.modal-body {
    max-height: 70vh;
    overflow-y: auto;
}

/* Add styles for the Last report column */
.table th[scope="col"]:nth-child(6),
.table td:nth-child(6) {
    min-width: 200px;
    /* Visit Duration column */
    max-width: 250px;
    white-space: normal;
    word-wrap: break-word;
}

.table th[scope="col"]:nth-child(7),
.table td:nth-child(7) {
    min-width: 800px;
    /* Last report column - wider */
    max-width: 1000px;
    white-space: normal;
    word-wrap: break-word;
}

.table th[scope="col"]:nth-child(8),
.table td:nth-child(8) {
    min-width: 150px;
    /* Area column */
    max-width: 200px;
    white-space: normal;
    word-wrap: break-word;
}

/* Ensure text wraps properly in all columns */
.table td:nth-child(6),
.table td:nth-child(7),
.table td:nth-child(8) {
    white-space: normal;
    word-wrap: break-word;
    line-height: 1.4;
}

.visit-duration-info {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    min-width: 180px;
}

.entry-info,
.exit-info,
.total-duration {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    white-space: nowrap;
}

.duration-label {
    font-weight: 500;
    color: #6c757d;
    min-width: 65px;
}

.duration-time {
    color: #2c3e50;
    font-weight: 500;
}

.duration-value {
    color: #2c3e50;
    font-weight: 500;
}

.total-duration {
    margin-top: 0.25rem;
    padding-top: 0.25rem;
    border-top: 1px dashed #dee2e6;
}

.eye-icon {
    font-size: 1rem;
    color: #7366ff;
    transition: color 0.2s ease;
}

.eye-icon:hover {
    color: #5549d6;
}

.date-display {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.date-display .date {
    font-weight: 500;
    color: #2c3e50;
}

.date-display .time {
    font-size: 0.8rem;
    color: #6c757d;
}

.last-report-content {
    display: flex;
    align-items: flex-start;
    gap: 0.5rem;
    line-height: 1.4;
}

.last-report-content .btn-link {
    white-space: nowrap;
    color: #7366ff;
    font-weight: 500;
}

.last-report-content .btn-link:hover {
    color: #5549d6;
}

.cursor-pointer {
    cursor: pointer;
}

.cursor-pointer:hover {
    color: #007bff;
}

.eye-icon {
    font-size: 18px;
    color: #6c757d;
    transition: color 0.2s ease;
}

.eye-icon:hover {
    color: #007bff;
}
</style>