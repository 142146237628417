import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vModelDynamic as _vModelDynamic, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "container-fluid p-0" }
const _hoisted_2 = { class: "row m-0" }
const _hoisted_3 = { class: "col-12 p-0" }
const _hoisted_4 = { class: "login-card" }
const _hoisted_5 = { class: "login-main" }
const _hoisted_6 = { class: "form-group" }
const _hoisted_7 = { class: "form-group" }
const _hoisted_8 = { class: "form-input position-relative" }
const _hoisted_9 = ["type"]
const _hoisted_10 = { class: "form-group mb-0" }
const _hoisted_11 = { class: "link" }
const _hoisted_12 = { class: "form-group" }
const _hoisted_13 = { class: "login-social" }
const _hoisted_14 = {
  href: "https://www.linkedin.com",
  target: "_blank"
}
const _hoisted_15 = {
  href: "https://twitter.com",
  target: "_blank"
}
const _hoisted_16 = {
  href: "https://www.facebook.com",
  target: "_blank"
}
const _hoisted_17 = {
  href: "https://www.instagram.com",
  target: "_blank"
}
const _hoisted_18 = { class: "mt-4 mb-0 text-center" }

import { ref, nextTick } from "vue";
import { createToaster } from "@meforma/vue-toaster";
import { useRouter } from 'vue-router';
import { useProjectStore } from "@/store/project";
import axios from 'axios';
import { useMenuStore } from "@/store/menu";


export default /*@__PURE__*/_defineComponent({
  __name: 'loginPage',
  setup(__props) {

const baseUrl = process.env.VUE_APP_BASE_URL;
const store = useProjectStore();
const toaster = createToaster({});
const router = useRouter();
const type = ref<string>('password');

const email = ref<string>("");
const password = ref<string>("");

// Add axios interceptor to set token in headers
axios.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

function showPassword() {
    if (type.value === 'password') {
        type.value = 'text';
    } else {
        type.value = 'password';
    }
}

async function doLogin() {
    console.log('Attempting to login...');
    try {
        const response = await axios.post(`${baseUrl}api/user/login`, {
            email: email.value,
            password: password.value
        });

        console.log('Login successful:', response.data);

        // Store the token
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('user', JSON.stringify(response.data.user));

        // Set sidebar type
        localStorage.setItem("SidebarType", 'compact-wrapper');

        // Initialize menu store
        const menuStore = useMenuStore();
        await menuStore.initializeMenu();

        // Force a router navigation
        await router.push('/');

        // Force page reload to ensure everything is initialized
        window.location.reload();

    } catch (error: any) {
        console.log('Login failed:', error);
        toaster.show(error.response.data.message, {
            theme: 'outline',
            position: 'top-right',
            icon: 'times',
            type: 'error',
            duration: 2000
        });
    }
}

function logout() {
    router.replace('/auth/login');
    localStorage.clear('user');
}

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_vue_feather = _resolveComponent("vue-feather")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", null, [
            _cache[12] || (_cache[12] = _createElementVNode("div", null, null, -1)),
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("form", {
                class: "theme-form",
                onSubmit: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"]))
              }, [
                _cache[9] || (_cache[9] = _createElementVNode("h4", { class: "text-center" }, "Sign in to account", -1)),
                _cache[10] || (_cache[10] = _createElementVNode("p", { class: "text-center" }, "Enter your email & password to login", -1)),
                _createElementVNode("div", _hoisted_6, [
                  _cache[3] || (_cache[3] = _createElementVNode("label", { class: "col-form-label" }, "Email Address", -1)),
                  _withDirectives(_createElementVNode("input", {
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((email).value = $event)),
                    class: "form-control",
                    type: "email",
                    placeholder: "Test@gmail.com"
                  }, null, 512), [
                    [_vModelText, email.value]
                  ])
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _cache[4] || (_cache[4] = _createElementVNode("label", { class: "col-form-label" }, "Password", -1)),
                  _createElementVNode("div", _hoisted_8, [
                    _withDirectives(_createElementVNode("input", {
                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((password).value = $event)),
                      type: type.value,
                      class: "form-control",
                      name: "login[password]",
                      placeholder: "*********"
                    }, null, 8, _hoisted_9), [
                      [_vModelDynamic, password.value]
                    ]),
                    _createElementVNode("div", { class: "show-hide" }, [
                      _createElementVNode("span", {
                        class: "show",
                        onClick: showPassword
                      })
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_10, [
                  _cache[6] || (_cache[6] = _createElementVNode("div", { class: "checkbox p-0" }, [
                    _createElementVNode("input", {
                      id: "checkbox1",
                      type: "checkbox"
                    }),
                    _createElementVNode("label", {
                      class: "text-muted",
                      for: "checkbox1"
                    }, "Remember password")
                  ], -1)),
                  _createElementVNode("a", _hoisted_11, [
                    _createVNode(_component_router_link, { to: "/authentication/forgetpassword" }, {
                      default: _withCtx(() => _cache[5] || (_cache[5] = [
                        _createTextVNode("Forgot password?")
                      ])),
                      _: 1
                    })
                  ]),
                  _createElementVNode("div", { class: "text-end mt-3" }, [
                    _createElementVNode("button", {
                      class: "btn btn-primary btn-block w-100",
                      type: "submit",
                      onClick: doLogin
                    }, "Sign in ")
                  ])
                ]),
                _cache[11] || (_cache[11] = _createElementVNode("div", { class: "login-social-title" }, [
                  _createElementVNode("h6", null, "Or Sign in with ")
                ], -1)),
                _createElementVNode("div", _hoisted_12, [
                  _createElementVNode("ul", _hoisted_13, [
                    _createElementVNode("li", null, [
                      _createElementVNode("a", _hoisted_14, [
                        _createVNode(_component_vue_feather, { type: "linkedin" })
                      ])
                    ]),
                    _createElementVNode("li", null, [
                      _createElementVNode("a", _hoisted_15, [
                        _createVNode(_component_vue_feather, { type: "twitter" })
                      ])
                    ]),
                    _createElementVNode("li", null, [
                      _createElementVNode("a", _hoisted_16, [
                        _createVNode(_component_vue_feather, { type: "facebook" })
                      ])
                    ]),
                    _createElementVNode("li", null, [
                      _createElementVNode("a", _hoisted_17, [
                        _createVNode(_component_vue_feather, { type: "instagram" })
                      ])
                    ])
                  ])
                ]),
                _createElementVNode("p", _hoisted_18, [
                  _cache[8] || (_cache[8] = _createTextVNode("Don't have account?")),
                  _createVNode(_component_router_link, {
                    class: "ms-2",
                    to: "/auth/register"
                  }, {
                    default: _withCtx(() => _cache[7] || (_cache[7] = [
                      _createTextVNode("Create Account")
                    ])),
                    _: 1
                  })
                ])
              ], 32)
            ])
          ])
        ])
      ])
    ])
  ]))
}
}

})